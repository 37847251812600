import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { setNftsList, setNftsMessage, resetNftsToInit } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getOwnsNfts } from "services/nftsService";
import Layout from "template";
import { Constant } from "template/Constant";
import { NftsForm } from "./form";
import { NftsTable } from "./table";


export const Nfts: React.FC = () => {
  const dispatch = useAppDispatch();
  const rData = useSelector((state: RootState) => state.nfts);
  const [row, setRow] = useState(undefined);
  const [action, setAction] = useState('');

  const userProfile = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")): undefined;
  const name = String(Object.values(userProfile)[1]);


  const getData = (page, pageSize, searchKey) => {
    getOwnsNfts(Object.values(userProfile)[0]).then((response) => {
      if (response && response.records) {
        dispatch(setNftsList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.totalCount, searchKey: searchKey }));
      } else {
        dispatch(setNftsMessage("No Record Found"));
      }
    })
  }

  const handleRowEdit = (rowData) => {
    setRow(rowData);
    dispatch(setNftsMessage(''));
    setAction('edit');
  }
  const handleReset = () => {
    dispatch(resetNftsToInit());
    getData(Constant.defaultPageNumber, rData.pageSize, '');
  }
  return (
    <Layout title="Mis Franquicias"  icon="icon_MisNftColor_icon w-30" backlink={"#"} >
      <div className="container-fluid pt-body">
          <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
					  <h2 className="font-w600 mr-auto "> 
						  <span className="mm-active">
                  <div className="personalizedicon"> <div className="icon_MisNftColor_icon w-30"></div> </div> 
              </span> 
              {'Mis Franquicias'}
					</h2>
          <div className="d-flex">
            <Button variant="light" className="btn-circle btn-sm ml-2" onClick={handleReset}><i className="fa fa-refresh"></i></Button>
          </div>
        </div>
        <div className="d-flex flex-column min-vh-100">
          {rData.message ?
            <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">{rData.message}
              <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => dispatch(setNftsMessage(''))}>
                <span aria-hidden="true">&times;</span>
              </Button>
            </Alert> : null}
          {action ? <NftsForm hideShowForm={setAction} action={action} row={row} getData={getData} /> :
            <NftsTable handleRowEdit={handleRowEdit} hideShowForm={setAction} getData={getData} />}
        </div>

      </div>
    </Layout >
  );
};

