import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IWallet {
id:number,
user_id:number,
ammount:number,
current_in_hold: number,
created_at:Date,
updated_at:Date
}

interface IWalletData {
    list?: Array<IWallet>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IWalletData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const walletSlice = createSlice({
    name: "wallet",
    initialState,
    reducers: {
        setWalletList: (state, _action: PayloadAction<IWalletData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetWalletToInit: (state) => {
            state = initialState;
        },
        setWalletMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setWalletList, resetWalletToInit, setWalletMessage } = walletSlice.actions;

export default walletSlice.reducer;

