import React, { Fragment, useState, useEffect, PropsWithChildren } from "react";
import { RootState } from "redux/reducers";
import { useSelector } from "react-redux";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
// import LoadingBar from 'react-top-loading-bar';
import {ModalValidate} from '../components/modal';

const Layout: React.FC<{title:string, icon:string, backlink:string}> = (props, {title, icon, backlink}) => {

    const isSidebar = useSelector((state: RootState) => state.template.sidebar);
    const progress = 100;
    
    const userProfile = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")): undefined;
    const name = String(Object.values(userProfile)[1]);

    const [popupValidation, setPopupValidation] = useState("dn");
    const [openModal, setopenModal] = useState(false);

    useEffect(() => {
        document.body.classList.toggle('sidebar-toggled', isSidebar);
    }, [isSidebar])

    useEffect(() => {
        document.body.classList.toggle('LoginBp', false);

        if(userProfile.validation_status <= 0){
            setPopupValidation("show")
            setopenModal(true)
        }
    }, []);

    // useEffect(() => {

    // }, [popupValidation]);

    

    

    return (
        <>
            {openModal ? <ModalValidate letmodal={true} />: null}
            <div id="wrapper" >
                {/* <LoadingBar color="#00E6FF" height={4} progress={progress} /> */}
                <Sidebar></Sidebar>
                <div id="content-wrapper" className={isSidebar ? 'd-flex flex-column main-toggled ' : 'd-flex flex-column main'}>
                    <div id="content" className="content-body">
                        <Topbar title={props.title}  icon={props.icon} backlink={props.backlink}></Topbar>
                        {props.children}
                        <Footer></Footer>
                    </div>
                </div>

            </div>
        </>
    );
};

export default Layout;

