import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IItem_Activities {
id:number,
nft_id:string,
event:string,
price:string,
from_user:string,
to_user:string,
created_at:Date
}

interface IItem_ActivitiesData {
    list?: Array<IItem_Activities>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IItem_ActivitiesData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const item_activitiesSlice = createSlice({
    name: "item_activities",
    initialState,
    reducers: {
        setItem_ActivitiesList: (state, _action: PayloadAction<IItem_ActivitiesData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetItem_ActivitiesToInit: (state) => {
            state = initialState;
        },
        setItem_ActivitiesMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setItem_ActivitiesList, resetItem_ActivitiesToInit, setItem_ActivitiesMessage } = item_activitiesSlice.actions;

export default item_activitiesSlice.reducer;

