import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from "./login";
import Register from "./register";
import AuthenticatedRoute from "components/auth";
import { Dashboard } from "components/dashboard";
import { FileUpload } from "components/upload";
import Checkout from "./checkout";
import { NotFound } from "./404";
import { Users, Nfts, Nft_Views, Nft_Owners, Nft_Favorites, Locations, Item_Activities, Franchises_Detail, Franchises, Referrals, Tradingview, Aboutrent, Roadmap, ComingSoon, View_Nft, Profile, ThankYou, PurchaseConfirmation, Commission, Secondary_markets, Secondary_market_detail, CommissionManage, Offers_module, Offers_sent, Wallet, Wallet_Transactions} from "components";

const Pages: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />}>
        </Route>
        <Route path="/register/:id" element={<Register />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/thankyou" element={<ThankYou />}></Route>
        <Route path="/confirmation/*" element={<PurchaseConfirmation /> }></Route>
        <Route path="/checkout" element={<AuthenticatedRoute element={<Checkout />} />}></Route>
        <Route path="/dashboard" element={<AuthenticatedRoute element={<Dashboard />} />}></Route>
        <Route path="/upload" element={<AuthenticatedRoute element={<FileUpload />} />}></Route>
        <Route path="/users" element={<AuthenticatedRoute element={<Users />} />}></Route>
        <Route path="/profile" element={<AuthenticatedRoute element={<Profile />} />}></Route>
        <Route path="/referrals" element={<AuthenticatedRoute element={<Referrals />} />}></Route>
        <Route path="/nfts" element={<AuthenticatedRoute element={<Nfts />} />}></Route>
        <Route path="/collection/:id" element={<AuthenticatedRoute element={<View_Nft />} />}></Route>

        <Route path="/nft_views" element={<AuthenticatedRoute element={<Nft_Views />} />}></Route>
        <Route path="/nft_owners" element={<AuthenticatedRoute element={<Nft_Owners />} />}></Route>
        <Route path="/nft_favorites" element={<AuthenticatedRoute element={<Nft_Favorites />} />}></Route>
        <Route path="/locations/:id" element={<AuthenticatedRoute element={<Locations />} />}></Route>
        <Route path="/item_activities" element={<AuthenticatedRoute element={<Item_Activities />} />}></Route>
        <Route path="/franchises_detail/:id" element={<AuthenticatedRoute element={<Franchises_Detail />} />}></Route>
        <Route path="/franchises" element={<AuthenticatedRoute element={<Franchises />} />}></Route>

        <Route path="/wallet" element={<AuthenticatedRoute element={<Wallet />} />}></Route>
        <Route path="/wallet_transactions" element={<AuthenticatedRoute element={<Wallet_Transactions />} />}></Route>

        <Route path="/tradingview" element={<AuthenticatedRoute element={<Tradingview />} />}></Route> 
        <Route path="/aboutrent" element={<AuthenticatedRoute element={<Aboutrent />} />}></Route> 
        <Route path="/roadmap" element={<AuthenticatedRoute element={<Roadmap />} />}></Route> 
        <Route path="/soon/:id" element={<AuthenticatedRoute element={<ComingSoon />} />}></Route>
        <Route path="/commission" element={<AuthenticatedRoute element={<Commission />} />}></Route>
        <Route path="/secondary_markets" element={<AuthenticatedRoute element={<Secondary_markets />} />}></Route>
        <Route path="/secondary_market_detail/:id" element={<AuthenticatedRoute element={<Secondary_market_detail />} />}></Route>
        <Route path="/commission_manage" element={<AuthenticatedRoute element={<CommissionManage />} />}></Route>
        <Route path="/offers_module" element={<AuthenticatedRoute element={<Offers_module/>} />}></Route>
        <Route path="/offers_sent" element={<AuthenticatedRoute element={<Offers_sent/>} />}></Route>


        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Pages;

