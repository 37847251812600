import React from 'react'
import { OffersDonuts } from './OffersDonuts'

type receivedAndSentOffers = {
    sentOffers: number,
    receivedOffers: number,
}

export const ConsolidatedOGraphCont: React.FC <receivedAndSentOffers>= ({ sentOffers,receivedOffers}) =>  {
    

    return (
        <div className="col-xl-5 col-xxl-8">
            <div className="card"style={{
                   paddingBottom: "20%",
                }}>
                <div className="card-header border-0 flex-wrap  select-btn">
                    <div>
                        <h4 className="fs-18 ">Consolidado de ofertas</h4>
                    </div>
                </div>
                <div className="card-body" style={{
                    display: "flex",
                    justifyContent: "space-around",
                }}>
                    <OffersDonuts sentOffers={sentOffers} receivedOffers={receivedOffers} offersToShow={sentOffers} textTitle='Realizadas' />
                    <OffersDonuts sentOffers={sentOffers} receivedOffers={receivedOffers} offersToShow={receivedOffers} textTitle='Recibidas' />
                </div>
            </div>
        </div>
    )
}
