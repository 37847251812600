import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetItem_ActivitiesToInit, setItem_ActivitiesMessage } from "redux/actions";
import { Button, Card, Col, Form, InputGroup } from "react-bootstrap";
import { Constant } from "template/Constant";

type Props = {
    hideShowForm: (action) => void;
    handleRowEdit: (row) => void;
    getData: (page, pageSize, searchKey) => void;
};

export const Item_ActivitiesTable: React.FC<Props> = ({ hideShowForm, handleRowEdit, getData }) => {
    const dispatch = useAppDispatch();
    const rData = useSelector((state: RootState) => state.item_activities);

    
    const handlePerRowsChange = async (newPerPage, page) => {
        await getData(page, newPerPage, '');
    }
    const handlePageChange = (page) => {
        getData(page, rData.pageSize, '');
    };
    useEffect(() => {
        if (rData && rData.list && rData.list.length === 0) {
            dispatch(resetItem_ActivitiesToInit());
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
        }
    }, [rData.list.length])

    const handleRowSelection = (row) => {
        console.log(row); // Row Selection Functionality can be written here
    }

    const handleCreateImage = (imgageUrl) => {
        // const img = <img className="w-full md:w-4/5 z-50" src = {imgageUrl} alt="avatar image" /> ;
        // return {__html : '<img src="'+imgageUrl+'" />'};
        return  "";
    }



    const columns = [
        {name: 'Franquicias', selector: row => row.description, sortable: true},
        {name: 'De', selector: row => handleCreateImage(row.from_avatar)+row.from_name, sortable: true},
        {name: 'Para', selector: row => handleCreateImage(row.to_avatar)+row.to_name, sortable: true},
        {name: 'Fecha', selector: row => row.created_at, sortable: true},
        {name: 'Contratos', selector: row => row.price, sortable: true},
        {name: 'Participación', selector: row => row.price, sortable: true},
        {name: 'price', selector: row => row.price, sortable: true},
        {name: 'event', selector: row => row.event, sortable: true},
    ];


    return (
        <Card className="shadow mb-4">
            <Card.Body className="p-0">
                <div className="table-responsive">
                    <DataTable
                        onSelectedRowsChange={handleRowSelection}
                        paginationPerPage={Constant.defaultPageNumber}
                        paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
                        columns={columns} data={rData.list}
                        onChangeRowsPerPage={handlePerRowsChange}
                        paginationTotalRows={rData.totalCount}
                        className="table table-striped text-white"
                        pagination
                        paginationServer
                        onChangePage={handlePageChange}></DataTable>
                </div>
            </Card.Body>
        </Card>
    );
}

