import React from 'react'
import { ParticipationsDonut } from './ParticipationsDonut';

type props={
    participationsDonutInfo: any;
}

export const ActiveParticipations: React.FC<props> = ({participationsDonutInfo}) =>{
  return (
      <div className="col-xl-4 col-sm-6 m-t35">
          <div className="card"
            style={
                {
                    display: 'grid',
                    placeItems: 'center',
                    paddingBottom: '15%',
                }
            }
          >
              <div className="card-header border-0 flex-wrap  select-btn">
                  <div className="mb-3">
                      <h4 className="fs-18">Participaciones Activas</h4>
                      <p className="mb-0 fs-12 ">LLC’s, participaciones y contratos</p>
                  </div>
              </div>
              <div className="card-body">
              <ParticipationsDonut participationsDonutInfo={participationsDonutInfo} />
              </div>
          </div>
      </div>
  )
}
