import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetWallet_TransactionsToInit, setWallet_TransactionsMessage } from "redux/actions";
import { Button, Card, Col, Form, InputGroup } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteWallet_Transactions } from "services/wallet_transactionsService";
type Props = {
    hideShowForm: (action) => void;
    handleRowEdit: (row) => void;
    getData: (page, pageSize, searchKey) => void;
};
export const Wallet_TransactionsTable: React.FC<Props> = ({ hideShowForm, handleRowEdit, getData }) => {
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState('');
    const [showDelete, setShowDelete] = useState(false);
    const [rowData, setRowData] = useState(undefined);
    const rData = useSelector((state: RootState) => state.wallet_transactions);
    const handleSearch = () => {
        if (search.length > 0) {
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
        }
    }
    const handlePerRowsChange = async (newPerPage, page) => {
        await getData(page, newPerPage, '');
    }
    const handlePageChange = (page) => {
        getData(page, rData.pageSize, '');
    };
    const handleRowDeleteClick = (row) => {
        setRowData(row);
        setShowDelete(true);
    }
    useEffect(() => {
        if (rData && rData.list && rData.list.length === 0) {
            dispatch(resetWallet_TransactionsToInit());
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
        }
    },[rData.list.length])
    const handleReset = () => {
        setSearch('');
        dispatch(resetWallet_TransactionsToInit());
        getData(Constant.defaultPageNumber, rData.pageSize, '');
    }
    const handleServerDelete = async () => {
        if (rowData) {
            const response = await deleteWallet_Transactions(rowData.id);
            if (response) {
                dispatch(resetWallet_TransactionsToInit());
                dispatch(setWallet_TransactionsMessage("Deleted Successfully"));
                getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                setShowDelete(false);
            } else {
                dispatch(setWallet_TransactionsMessage("Some error occured!"));
            }
        }
    }

    const handleRowSelection = (row) => {
        console.log(row); // Row Selection Functionality can be written here
    }
    const handleAddButtonClick = () => {
        dispatch(setWallet_TransactionsMessage(''));
        hideShowForm('add');
    }

    const columns = [
{name: 'user_from', selector: row => row.user_from, sortable: true},
{name: 'user_to', selector: row => row.user_to, sortable: true},
{name: 'ammount', selector: row => row.ammount, sortable: true},
{name: 'transaction_status', selector: row => row.transaction_status, sortable: true},
{name: 'observation', selector: row => row.observation, sortable: true},
{name: 'created_at', selector: row => row.created_at, sortable: true},
    ];
    return (
            <Card className="shadow mb-4">
                <Card.Header className="py-3">
                    <h1 className="h3 mb-0 text-gray-800"> Transacciones Realizadas ({rData.totalCount})
                        <Button variant="light" className="btn-circle btn-sm ml-2" onClick={handleReset}><i className="fa fa-refresh"></i></Button>
                        <Button className="btn-icon-split float-right"  style={{marginLeft:'100px'}} onClick={handleAddButtonClick}>
                            <span className="icon ">
                                <i className="fas fa-add"></i>
                            </span>
                            <span className="text">Agregar Transacción</span>
                        </Button>
                        </h1>

                </Card.Header>
                <Card.Body>
                    
                    <div className="table-responsive">
                        <DataTable
                            selectableRows={false}
                            onSelectedRowsChange={handleRowSelection}
                            paginationPerPage={Constant.defaultPageNumber}
                            paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
                            columns={columns} data={rData.list}
                            onChangeRowsPerPage={handlePerRowsChange}
                            paginationTotalRows={rData.totalCount}
                            className="table table-bordered"
                            pagination
                            paginationServer
                            onChangePage={handlePageChange}></DataTable>
                    </div>
                </Card.Body>
                <ConfirmationModal buttonNegative="Cancel" buttonPositive="Delete" title="Delete Confirmation" show={showDelete} body={"Are you sure?"} onNegative={() => setShowDelete(false)} onPositive={handleServerDelete} />
            </Card>
    );
}

