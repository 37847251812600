import { APIService } from "services";

export const getNft_Views = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllNft_Views(pageNo,pageSize);
    }
    else{
        try {
            res = await searchNft_Views(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data){
    return res.data;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addNft_Views = (data) => {
return APIService.api().post(`/nft_views`,data)
}
export const updateNft_Views = (id,data) => {
return APIService.api().patch(`/nft_views/${id}`,data)
}
export const getAllNft_Views = (pageNo,pageSize) => {
return APIService.api().get(`/nft_views/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const getOneNft_Views = (id) => {
return APIService.api().get(`/nft_views/${id}`)
}
export const searchNft_Views = (searchKey,pageNo,pageSize) => {
return APIService.api().get(`/nft_views/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const deleteNft_Views = (id) => {
return APIService.api().delete(`/nft_views/${id}`)
}
