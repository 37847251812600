import React from 'react';

export const Loader: React.FC = () => {
    return (
        <>
            <div className='cube-loader-container'>
            <div className="cube-loader">
                <div className="cube-top"></div>
                <div className="cube-wrapper">
                    <span className="cube-span cube-span-0"></span>
                    <span className="cube-span cube-span-1"></span>
                    <span className="cube-span cube-span-2"></span>
                    <span className="cube-span cube-span-3"></span>
                </div>
            </div>
            </div>
                <h4 className='center-th-td '>cargando ...</h4>
        </>
    )
}