import React, { useEffect, useRef } from 'react';
import { Chart, BarElement, CategoryScale, LinearScale } from 'chart.js';
Chart.register(BarElement,CategoryScale,LinearScale);

interface IncomeExpenseData {
    month: number;
    total_income_month: number;
    total_expenses_month: number;
    year: number;
  }
  
  interface MonthlyIncomeExpenseChartProps {
    data: IncomeExpenseData[];
  }
  


export const IncomeAndExpenses: React.FC<MonthlyIncomeExpenseChartProps> = ({ data }) => {

    const chartRef = useRef<HTMLCanvasElement>(null);
  
    
    const monthsLabels = [
      "ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"
    ];
  
    const incomes = data?.map(entry => entry.total_income_month);
    const expenses = data?.map(entry => entry.total_expenses_month);
    const labels = data?.map(entry => `${monthsLabels[entry.month - 1]} ${entry.year}`);
  
    useEffect(() => {
      if (chartRef.current) {
        const chartInstance = new Chart(chartRef.current, {
          type: 'bar',
          data: {
            labels: labels,
            datasets: [
              {
                label: 'Ingresos USD',
                data: incomes,
                backgroundColor: '#101c2e',
                borderWidth: 1
              },
              {
                label: 'Gastos USD',
                data: expenses,
                backgroundColor: '#62c5e3',
                borderWidth: 1
              }
            ]
          },
          options: {
            scales: {
              x: {
                beginAtZero: true
              },
              y: {
                beginAtZero: true
              }
            },
            plugins: {
                legend: {
                  display: true,
                  position:"top",
                  align: 'center',
                }
              }
          }
        });
  
        return () => {
          chartInstance.destroy();
        };
      }
    }, [labels, incomes, expenses]);

    
    return (<div className="col-xl-12 col-xxl-8" >
            <div className="card"
            style={
                {
                    paddingTop: '50px',
                }
            }
            >
                <div className="card-body">
                <canvas ref={chartRef} width="200" height="100"></canvas>
                </div>
            </div>
        </div>
    )
}
