import { combineReducers } from "redux";

import template from "redux/slices/template";
import authToken from "redux/slices/auth";

import users from "redux/slices/users";
import nfts from "redux/slices/nfts";
import nft_views from "redux/slices/nft_views";
import nft_owners from "redux/slices/nft_owners";
import nft_favorites from "redux/slices/nft_favorites";
import locations from "redux/slices/locations";
import item_activities from "redux/slices/item_activities";
import franchises_detail from "redux/slices/franchises_detail";
import franchises from "redux/slices/franchises";
import wallet from "redux/slices/wallet";
import wallet_transactions from "redux/slices/wallet_transactions";
import wallet_financials_details from "redux/slices/wallet_financials_details";


const rootReducer = combineReducers({ template,authToken,users,nfts,nft_views,nft_owners,nft_favorites,locations,item_activities,franchises_detail,franchises,wallet, wallet_transactions,wallet_financials_details });

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

