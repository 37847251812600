import { APIService } from "services";

export const getUsers = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllUsers(pageNo,pageSize);
    }
    else{
        try {
            res = await searchUsers(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data){
    return res.data;
    }else{
    return { records:[], totalCount:0 }
    }
    
}

export const getReferrals = async (parent_id) => {
    let res;
    try {
        res = await APIService.api().get(`/users/byreferral/${parent_id}`);
    } catch(err) {
            return { records:[], totalCount:0 }
        }
    if(res && res.data){
        return res.data;
    }else{
        return { records:[], totalCount:0 }
    }
    
}

export const addUsers = (data) => {
return APIService.api().post(`/users`,data)
}
export const updateUsers = (id,data) => {
return APIService.api().patch(`/users/${id}`,data)
}
export const getAllUsers = (pageNo,pageSize) => {
return APIService.api().get(`/users/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const getOneUsers = (id) => {
return APIService.api().get(`/users/${id}`)
}
export const searchUsers = (searchKey,pageNo,pageSize) => {
return APIService.api().get(`/users/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const deleteUsers = (id) => {
return APIService.api().delete(`/users/${id}`)
}

export const validateCode = (code, email) => {
    return APIService.apiNoAuth().get(`/users/validateCode/${code}/${email}`);
}
export const forwardCode = (email) => {
    return APIService.apiNoAuth().get(`/users/forwardCode/${email}`);
}

export const passwordUpdate = (data) => {
    return APIService.apiNoAuth().post('/users/changePassword', data);
}

export const getProfileData = () => {
    return APIService.api().get(`/users/getreferidos/all`);
}

export const userUpdate = (data) => {
    return APIService.apiNoAuth().post('/users/updateProfile', data);
}

export const closeUserAccount = () => {
    return APIService.api().post('/users/disableaccount');
}

export const addImageBanner = (imageBanner) => {
    return APIService.api().post('/users/updatebanner', imageBanner);
}

export const addImageProfile = (imageProfile) => {
    return APIService.api().post('/users/updatephotoprofile', imageProfile);
}