import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from 'react-router-dom'
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppDispatch } from "redux/store";
import { userRegister } from "services/authService";
import { setJWTToken } from "redux/actions";
import { setError } from "redux/slices/auth";
import { Alert, Button, Form, InputGroup, FormControl, FormCheck } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { Constant } from "template/Constant";
import logo from '../assets/images/rent/RentLogoBlack_icon.svg';
import {WindowModal} from '../components/users/modal';

const Register: React.FC = () => {

    const navigate = useNavigate();
    const handleClose = () => {
        navigate('/', { replace: true });
    }
    const location = useLocation();
    let uri = location.pathname;
    let parts = uri.split("/");
    let query = parts[2];

    const dispatch = useAppDispatch();
    const rData = useSelector((state: RootState) => state.authToken);
    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
            name:'',
            lastname:'',
            parent_code: query,
            sexo:'',
            birthday:'',
            repeatPassword:'',
            phone:''
        },

        onSubmit: async (values) => {
            const response = await userRegister(values.username, values.password, values.name, values.lastname, values.parent_code, values.sexo, values.birthday, values.repeatPassword, values.phone);
            if (response) {
                dispatch(setJWTToken(response));
                setopenModal(true)
                //navigate('/franchises', { replace: true });

            } else {
                dispatch(setError("Invalid Credentials"))
            }
        },
        validationSchema: yup.object({
            username: yup.string().trim().required('Username is required'),
            password: yup.string().trim().required('Password is required'),
            name:     yup.string().trim().required('name'),
            lastname: yup.string().trim().required('lastname'),
            sexo: yup.string().trim().required('sexo'),
            birthday: yup.string().trim().required('birthday'),
            repeatPassword: yup.string().when("password", {
                is: val => (val && val.length > 0 ? true : false),
                then: yup.string().oneOf(
                  [yup.ref("password")],
                  "Both password need to be the same"
                )
              }),
            phone: yup.string().trim().required('phone')
        }),
    });


    const [passwordType, setPasswordType] = useState("password");
    const [passwordInput, setPasswordInput] = useState("");
    const [acceptInput, setAcceptInput] = useState(false);
    const [openModal, setopenModal] = useState(false);
    const styleSelect = {
        fontSize: '13px',
        paddingTop: '0rem !important', 
        paddingBottom: '0rem !important',
        paddingLeft: '10px !important',
    }
    
    const handlePasswordChange =(evnt)=>{
        setPasswordInput(evnt.target.value);
    }
    const togglePassword =()=>{
      if(passwordType==="password")
      {
       setPasswordType("text")
       return;
      }
      setPasswordType("password")
    }

    // const openModal = () => {
    //     setIsOpen(true);
    // }
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
      };
    
    Modal.setAppElement('#root');

    useEffect(() => {
        document.body.classList.toggle('LoginBp', true);
    }, [])


    return (

        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-xs-12">
                    <div className="card o-hidden border-0 bg-white shadow-lg my-6">
                        <div className="card-body p-0 login-card">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="pull-right p-10 default-select bg-white b-none">
                                        <div className="personalizedicon mr-0 pt-0" onClick={handleClose}>
                                            <div className="icon_Close_icon w-15"></div>
                                        </div>
                                    </div>
                                    <div className="p-5 p-t-30 p-b-60">
                                    <div className="text-center">
                                        <img src={logo} alt="logo" className="logo" />
                                        </div>
                                        <div className="text-center">
                                            <h1 className="h4 text-gray-900 mb-4">Creación de Cuenta</h1>
                                        </div>
                                        <Form className="user"  onSubmit={formik.handleSubmit}>
                                            <div className="row m-b-20">
                                                <div className="col-sm-6 mb-3 mb-sm-0">
                                                    <Form.Group>
                                                        <label className="text-gray-900 p-l-20">Nombre </label>
                                                        <div className="personalizedicon hidden-xs">
                                                            <div className="icon_NombreBlack_icon w-30"></div>
                                                        </div>
                                                        <Form.Control type="text" className="form-control form-control-user" id="name" 
                                                            value={formik.values.name}
                                                            placeholder="Nombre"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            isInvalid={!!formik.touched.name && !!formik.errors.name}
                                                            isValid={!!formik.touched.name && !formik.errors.name}
                                                        ></Form.Control>
                                                        {formik.errors.name && (
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.name}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </Form.Group>
                                                </div>
                                                <div className="col-sm-6 mb-3 mb-sm-0">
                                                    <Form.Group>
                                                        <label className="text-gray-900 p-l-20">Apellidos </label>
                                                        <div className="personalizedicon hidden-xs">
                                                            <div className="icon_NombreBlack_icon w-30"></div>
                                                        </div>
                                                        <Form.Control type="text" className="form-control form-control-user" id="lastname"
                                                            value={formik.values.lastname}
                                                            placeholder="lastname"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            isInvalid={!!formik.touched.lastname && !!formik.errors.lastname}
                                                            isValid={!!formik.touched.lastname && !formik.errors.lastname}
                                                        ></Form.Control>
                                                        {formik.errors.lastname && (
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.lastname}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row m-b-20">
                                                <div className="col-sm-6 mb-3 mb-sm-0">
                                                    <Form.Group>
                                                        <label className="text-gray-900 p-l-20">E-mail </label>
                                                        <div className="personalizedicon hidden-xs">
                                                            <div className="icon_MailBlack_icon w-30"></div>
                                                        </div>
                                                        <Form.Control type="email" className="form-control form-control-user" id="username" 
                                                            value={formik.values.username}
                                                            placeholder="E-mail"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            isInvalid={!!formik.touched.username && !!formik.errors.username}
                                                            isValid={!!formik.touched.username && !formik.errors.username}
                                                        ></Form.Control>
                                                        {formik.errors.username && (
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.username}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </Form.Group>
                                                </div>
                                                <div className="col-sm-6 mb-3 mb-sm-0">
                                                    <Form.Group>
                                                        <label className="text-gray-900 p-l-20">Código de Afiliado (opcional) </label>
                                                        <div className="personalizedicon hidden-xs">
                                                            <div className="icon_Code_icon w-30"></div>
                                                        </div>
                                                        <Form.Control type="text" className="form-control form-control-user" id="parent_code"
                                                            value={formik.values.parent_code}
                                                            placeholder="Código de Registro"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            isInvalid={!!formik.touched.parent_code && !!formik.errors.parent_code}
                                                            isValid={!!formik.touched.parent_code && !formik.errors.parent_code}
                                                        ></Form.Control>
                                                        {formik.errors.parent_code && (
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.parent_code}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row m-b-20">
                                                <div className="col-sm-6 mb-3 mb-sm-0">
                                                    <Form.Group>
                                                        <label className="text-gray-900 p-l-20">Sexo </label>
                                                        <div className="personalizedicon hidden-xs">
                                                            <div className="icon_Sexo_icon w-30"></div>
                                                        </div>
                                                        <select aria-label="Default select example" className="form-control" id="sexo"
                                                            value={formik.values.sexo}
                                                            placeholder="sexo"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            style = {styleSelect}
                                                        >
                                                            <option>Selecciona tu Sexo</option>
                                                            <option value="Hombre">Hombre</option>
                                                            <option value="Mujer">Mujer</option>
                                                            <option value="Otro">Otro</option>
                                                        </select>
                                                        {formik.errors.sexo && (
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.sexo}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </Form.Group>
                                                </div>
                                                <div className="col-sm-6 mb-3 mb-sm-0">
                                                <Form.Group>
                                                        <label className="text-gray-900 p-l-20">Fecha de Nacimiento </label>
                                                        <div className="personalizedicon hidden-xs">
                                                            <div className="icon_FechaBlack_icon w-30"></div>
                                                        </div>
                                                        <Form.Control type="date" className="form-control form-control-user" id="birthday" 
                                                            value={formik.values.birthday}
                                                            placeholder="Fecha de Nacimiento"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            isInvalid={!!formik.touched.birthday && !!formik.errors.birthday}
                                                            isValid={!!formik.touched.birthday && !formik.errors.birthday}
                                                        ></Form.Control>
                                                        {formik.errors.birthday && (
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.birthday}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </Form.Group>  
                                                </div>
                                            </div>
                                            <div className="row m-b-20">
                                                <div className="col-sm-6 mb-3 mb-sm-0">

                                                    <Form.Group>
                                                        <label className="text-gray-900 p-l-20">Contraseña </label>
                                                        <div className="personalizedicon hidden-xs">
                                                            <div className="icon_KeyBlack_icon w-30"></div>
                                                        </div>
                                                        <Form.Control type="password" className="form-control form-control-user" id="password"
                                                            value={formik.values.password}
                                                            placeholder="Contraseña"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            isInvalid={!!formik.touched.password && !!formik.errors.password}
                                                            isValid={!!formik.touched.password && !formik.errors.password}
                                                        ></Form.Control>
                                                        {formik.errors.password && (
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.password}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </Form.Group>
                                                </div>
                                                <div className="col-sm-6 mb-3 mb-sm-0">
                                                    <Form.Group>
                                                        <label className="text-gray-900 p-l-20">Confirmar Contraseña </label>
                                                        <div className="personalizedicon hidden-xs">
                                                            <div className="icon_ConfimationKeyBlack_icon w-30"></div>
                                                        </div>
                                                        <Form.Control type="password" className="form-control form-control-user" id="repeatPassword"
                                                            value={formik.values.repeatPassword}
                                                            placeholder="Repeat Password"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            isInvalid={!!formik.touched.repeatPassword && !!formik.errors.repeatPassword}
                                                            isValid={!!formik.touched.repeatPassword && !formik.errors.repeatPassword}
                                                        ></Form.Control>
                                                        {formik.errors.repeatPassword && (
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.repeatPassword}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row m-b-20">
                                                <div className="col-sm-6 mb-3 mb-sm-0">
                                                    <Form.Group>
                                                        <label className="text-gray-900 p-l-20">Número de Teléfono </label>
                                                        <div className="personalizedicon hidden-xs">
                                                            <div className="icon_Cel_icon w-30"></div>
                                                        </div>
                                                        <Form.Control type="text" className="form-control form-control-user" id="phone" 
                                                            value={formik.values.phone}
                                                            placeholder="Teléfono"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            isInvalid={!!formik.touched.phone && !!formik.errors.phone}
                                                            isValid={!!formik.touched.phone && !formik.errors.phone}
                                                        ></Form.Control>
                                                        {formik.errors.phone && (
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.phone}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </Form.Group>
                                                </div>
                                                <div className="col-sm-6 mb-sm-0 mb-3">
                                                    <Form.Group>
                                                        <label className="text-gray-900 p-l-20"></label>
                                                        <div className="personalizedicon hidden-xs">
                                                            <div className="w-30"></div>
                                                        </div>
                                                        <Form.Check className="mt-1 form-control form-control-user" type={'switch'} id={`check-api-${'checkbox'}`}>
                                                            <Form.Check.Input type={'checkbox'} checked={acceptInput? true : false} onClick={()=> {setAcceptInput(!acceptInput)}} />
                                                            <Form.Check.Label style={{"color": "grey", "width": "90%", "marginLeft": "35px", "marginTop": "-20px"}}><small style={{"fontSize": "10px"}}>He leído y aceptado en su totalidad los</small> <a href="" onClick={(event) => {event.preventDefault(); window.open("https://nurent.net/terminos-y-condiciones/", "_blank")}} style={{"fontSize": "10px"}}>Términos y condiciones</a> <small style={{"fontSize": "10px"}}>y</small> <a style={{"fontSize": "10px"}} href="" onClick={(event) => {event.preventDefault(); window.open("https://nurent.net/tratamiento-datos/", "_blank")}}> la política de tratamiento de datos.</a></Form.Check.Label>
                                                        </Form.Check>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center m-t-30">
                                                <div className="col-sm-6 mb-3 mb-sm-0">
                                                    <Button type="submit" className="btn btn-primary btn-user btn-block btn-rent-white" disabled={acceptInput? false: true}>Crear Cuenta</Button>
                                                </div>
                                            </div>
                                        </Form>
                                        
                                        {openModal ? <WindowModal email={formik.values.username} letmodal={true} />: null}
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
    );
};

export default Register;

