import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetFranchises_DetailToInit, setFranchises_DetailMessage } from "redux/actions";
import { Button, Card, Col, Form, InputGroup } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteFranchises_Detail } from "services/franchises_detailService";
import { detailLocations } from "services/locationsService";
import { useNavigate } from "react-router";
import { useLocation } from 'react-router-dom';
import Parser from 'html-react-parser';
import DOMPurify from "dompurify";


type Props = {
    hideShowForm: (action) => void;
    handleRowEdit: (row) => void;
    getData: (page, pageSize, searchKey) => void;
};
export const Franchises_DetailTable: React.FC<Props> = ({ hideShowForm, handleRowEdit, getData }) => {

    const location = useLocation();
    const navigate = useNavigate();
    let uri = location.pathname;

    let parts = uri.split("/");
    let query = parts[2];

    const dispatch = useAppDispatch();
    const [search, setSearch] = useState('');
    const [showDelete, setShowDelete] = useState(false);
    const [rowData, setRowData] = useState(undefined);

    const [tipoFranquicia, settipoFranquicia] = useState("N/A");
    const [tipoVivienda, settipoVivienda] = useState("0");
    const [tipoCorporativa, settipoCorporativa] = useState("0");
    const [tipoServicioPublico, settipoServicioPublico] = useState("0");

    const rData = useSelector((state: RootState) => state.franchises_detail);
  
    const handleRowDeleteClick = (row) => {
        setRowData(row);
        setShowDelete(true);
    }
    useEffect(() => {
        if (rData && rData.list && rData.list.length === 0) {
            dispatch(resetFranchises_DetailToInit());
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
        }
    }, [rData.list.length])
    
    useEffect(() => {
        detailLocations(query).then((response) => {
            if(response.data.records.length > 1){
                settipoFranquicia("Mixta");
            }else{
                settipoFranquicia(response.data.records[0].property_type);
            }
            
            response.data.records.map( (r, i) => {
                
                if(r.property_type == 'De vivienda'){
                    settipoVivienda(r.t);
                }
                if(r.property_type == 'Corporativa'){
                    settipoCorporativa(r.t);
                }
                if(r.property_type == 'Servicio Publico'){
                    settipoServicioPublico(r.t);
                }

            });
            
            return '';
        })


      },[])

 
    const handleServerDelete = async () => {
        if (rowData) {
            const response = await deleteFranchises_Detail(rowData.id);
            if (response) {
                dispatch(resetFranchises_DetailToInit());
                dispatch(setFranchises_DetailMessage("Deleted Successfully"));
                getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                setShowDelete(false);
            } else {
                dispatch(setFranchises_DetailMessage("Some error occured!"));
            }
        }
    }


  

    const getInformation = (r) => {
        const table = ( <table className="table text-white"> 
                            <tr>
                                <td>TOTAL ACTIVOS FLINTSTONE´S AIRSHIPS LLC</td>
                                <td>{r.llc_total_activos}</td>
                            </tr>
                            <tr>
                                <td>VALOR DE ACTIVOS FLINTSTONES</td>
                                <td>{r.llc_valor_activos}</td>
                            </tr>
                            <tr>
                                <td>ONBOARDING JURIDICO Y DIGITAL</td>
                                <td>{r.llc_onboarding}</td>
                            </tr>
                            <tr>
                                <td>INGRESO AÑO 1 LLC</td>
                                <td>{r.llc_ingreso_ano1}</td>
                            </tr>
                            <tr>
                                <td>REVENUE ESPERADO</td>
                                <td>{r.llc_revenue}</td>
                            </tr>
                            <tr>
                                <td>GASTOS (TAX, SEGURO, PM)</td>
                                <td>{r.llc_gastos}</td>
                            </tr>
                            <tr>
                                <td>RESERVAS</td>
                                <td>{r.llc_reserva}</td>
                            </tr>
                            <tr>
                                <td>MANAGEMENT CONTABLE LLC SFH</td>
                                <td>{r.llc_management}</td>
                            </tr>
                            <tr>
                                <td>UTILIDAD NETA LLC PRS</td>
                                <td>{r.llc_utilidad}</td>
                            </tr>
                            <tr>
                                <td>ROI NETO PRS</td>
                                <td>{r.llc_roi}</td>
                            </tr>
                            <tr>
                                <td>UTILIDAD NETA LLC OPTIMO</td>
                                <td>{r.llc_utilidad_neta}</td>
                            </tr>
                            <tr>
                                <td>ROI NETO LLC OPTIMO</td>
                                <td>{r.llc_roi_neto}</td>
                            </tr>
                            <tr>
                                <td>REVALORIZACION ESTIMADA 5 AÑOS</td>
                                <td>{r.llc_revalorizacion_estimada}</td>
                            </tr>
                        </table>);
        return table;
    }
    const getTokenomics = (r) => {
        const table = ( <table className="table text-white"> 
                            <tr>
                                <td>VALOR DEL TICKET</td>
                                <td>{r.tk_valor_ticket}</td>
                            </tr>
                            <tr>
                                <td>TOTAL SOCIOS</td>
                                <td>{r.tk_total_socios}</td>
                            </tr>
                            <tr>
                                <td>TOTAL PARTICIPACIONES ENTREGADAS MAX FQ</td>
                                <td>{r.tk_total_particicaciones}</td>
                            </tr>
                            <tr>
                                <td>PARTICIPACION ACCIONARIA POR TICKET</td>
                                <td>{r.tk_participacion_ticket}</td>
                            </tr>
                            <tr>
                                <td>INGRESOS POR TICKET</td>
                                <td>{r.tk_ingresos_ticket}</td>
                            </tr>
                            <tr>
                                <td>REVENUE ESPERADO</td>
                                <td>{r.tk_revenue_esperado}</td>
                            </tr>
                            <tr>
                                <td>UTILIDAD NETA LLC PRS</td>
                                <td>{r.tk_utilidad_neta}</td>
                            </tr>
                            <tr>
                                <td>ROI NETO PRS</td>
                                <td>{r.tk_roi_neto}</td>
                            </tr>
                            <tr>
                                <td>UTILIDAD NETA LLC OPTIMO</td>
                                <td>{r.tk_utilidad_neta_optimo}</td>
                            </tr>
                            <tr>
                                <td>ROI NETO LLC OPTIMO</td>
                                <td>{r.tk_roi_neto_optimo}</td>
                            </tr>
                            <tr>
                                <td>REVALORIZACION ESTIMADA 5 AÑOS</td>
                                <td>{r.tk_revalorizacion_estimada}</td>
                            </tr>
        </table>);
        return table;

    }


    const userProfile = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")): undefined;
    const name = String(Object.values(userProfile)[1]);
   
    const functionButtons = (row) => {
        if(String(Object.values(userProfile)[6]) == "1"){
            return (
                <>
                    <Button variant="link" className="btn-sm text-white btn-info m-r-20" onClick={() => handleRowEdit(row)}>Edit</Button>
                    <Button variant="link" className="btn-sm text-white btn-danger" onClick={() => handleRowDeleteClick(row)}>Delete</Button>
                </>
            )
        }else{
            return "";
        }
    }
    return (
        <>
        <div className="row">
        {
            rData.list.map( (r, i) => (<>  
                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-body p-30" >
                            <h5 className="mb-30">INFO DETALLADA FRANQUICIA LLC
</h5>
                             {getInformation(r)}
                            {/* {functionButtons(r)} */}
                        </div>
                    </div>
                </div>     
                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-body p-30" >
                            <h5 className="mb-30">INFO DETALLADA PARTICIPACIONES (TOKENOMICS)</h5>
                            { getTokenomics(r) }
                            {/* {functionButtons(r)} */}
                        </div>
                    </div>
                </div>                 
            </>))    
        }
        </div>
        <Card className="shadow mb-4">
            <ConfirmationModal buttonNegative="Cancel" buttonPositive="Delete" title="Delete Confirmation" show={showDelete} body={"Are you sure?"} onNegative={() => setShowDelete(false)} onPositive={handleServerDelete} />
        </Card>
        </>
    );
}

