import { useFormik } from "formik";
import React from "react";

import { Button, Card, Form } from "react-bootstrap";
import { setNft_ViewsMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addNft_Views, updateNft_Views } from "services/nft_viewsService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const Nft_ViewsForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',ip:'',user_id:'',nft_id:'',created_at:''};
    const initialValue = action === 'edit' ? row : iValue;
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateNft_Views(values.id,values);
               if (response && (response.status === 200 || response.status === 201)) {
                    dispatch(setNft_ViewsMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setNft_ViewsMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addNft_Views(values);
               if (response && (response.status === 200 || response.status === 201)) {
                    dispatch(setNft_ViewsMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setNft_ViewsMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           ip: yup.string().required('ip is required'),
user_id: yup.string().required('user_id is required'),
nft_id: yup.string().required('nft_id is required'),
created_at: yup.date().required('created_at is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Nft_Views
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Nft_Views</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">ip</label>
<Form.Control type="text" name="ip" className="form-control" value={formik.values.ip}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.ip && !!formik.errors.ip}
isValid ={!!formik.touched.ip && !formik.errors.ip}
></Form.Control>
{
    formik.errors.ip && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.ip}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">user_id</label>
<Form.Control type="text" name="user_id" className="form-control" value={formik.values.user_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.user_id && !!formik.errors.user_id}
isValid ={!!formik.touched.user_id && !formik.errors.user_id}
></Form.Control>
{
    formik.errors.user_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.user_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">nft_id</label>
<Form.Control type="text" name="nft_id" className="form-control" value={formik.values.nft_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.nft_id && !!formik.errors.nft_id}
isValid ={!!formik.touched.nft_id && !formik.errors.nft_id}
></Form.Control>
{
    formik.errors.nft_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.nft_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">created_at</label>
<Form.Control type="date" name="created_at" className="form-control" value={formik.values.created_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.created_at && !!formik.errors.created_at}
isValid ={!!formik.touched.created_at && !formik.errors.created_at}
></Form.Control>
{
    formik.errors.created_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.created_at}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

