import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

type props = {
    consolidatedInfoOffersData: any;
    defaultFranchiseId: number,
    settingDefaultFranchiseID: (idFranchise: number) => void;
}

export const ConsolidatedInfoOffers: React.FC<props> = ({ consolidatedInfoOffersData,defaultFranchiseId,settingDefaultFranchiseID }) => {

    return (
        <div className="col-xl-7 col-xxl-4">
            <div className="card">
                <div className="card-header border-0 flex-wrap  select-btn">
                    <div className="mb-3">
                        <h4 className="fs-18 ">informacion Consolidada de Ofertas</h4>

                    </div>
                </div>
                <div className="card-body ">
                    <p className="mb-3 fs-18 ">Franquicias</p>
                    <Tabs
                        activeKey={defaultFranchiseId}
                        onSelect={(k) => settingDefaultFranchiseID(parseInt(k))}
                        id="fill-tab-example"
                        className="mb-3"
                        fill
                        style={
                            {
                                backgroundColor: '#a6b8be',
                                padding: '10px',
                                borderRadius: '10px',
                            }
                        }
                    >
                        {
                            consolidatedInfoOffersData?.map((item: any) => (
                                <Tab eventKey={item.franchise_id} title={item.description?.substring(1,item.description.length-3)}>
                                    <div style={
                                        {
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            paddingTop: '20px',
                                            paddingBottom: '20px',
                                        }
                                    }>
                                        <div style={{padding: '10px',}}>
                                            <h5 className='grid-title'>ofertas recibidas</h5>
                                            {item.ofertas_recibidas}

                                        </div>
                                        <div style={{padding: '10px',}}>
                                            <h5 className='grid-title'>ofertas realizadas</h5>
                                            {item.ofertas_realizadas}
                                        </div>
                                        <div style={{padding: '10px',}}>
                                            <h5 className='grid-title'>Mis participaciones</h5>
                                            {item.total_participations}
                                        </div>
                                    </div>
                                </Tab>
                            ))
                        }
                    </Tabs>

                </div>
            </div>
        </div>
    )
}
