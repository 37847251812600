import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppDispatch } from "redux/store";
import { tokenAPICALL } from "services/authService";
import { setJWTToken } from "redux/actions";
import { setError } from "redux/slices/auth";
import { Alert, Button, Form, InputGroup, FormControl } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { Constant } from "template/Constant";
import logo from '../../assets/images/rent/RentLogoBlack_icon.svg';

export const ThankYou: React.FC = () => {
    return (
        <div className="container-fluid LoginBp">
            <div className="container">
                <div className="row d-flex justify-content-center align-items-center vh-100">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="card o-hidden border-0 bg-white shadow-lg">
                            <div className="card-body p-0 login-card">
                                <div className="row">
                                    <div className="col-lg-12 col-md-offset-12">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <img src={logo} alt="logo" className="logo" />
                                            </div>
                                            <div className="text-center">
                                                <h1 className="h4 text-gray-900 mb-4">Gracias por validar tu información</h1>
                                            </div>
											<p className="text-gray-900 text-center">
												Estarémos notificandote vía e-mail en cuanto el proceso se haya validado.
											</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    );
};
