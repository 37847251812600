import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetWalletToInit, setWalletMessage } from "redux/actions";
import { Button, Card, Col, Form, InputGroup } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteWallet } from "services/walletService";
import { ModalRetiramentWallet } from "./modalRetiramentWallet";

type Props = {
    hideShowForm: (action) => void;
    handleRowEdit: (row) => void;
    getData: (page, pageSize, searchKey) => void;
};
export const WalletTable: React.FC<Props> = ({ hideShowForm, handleRowEdit, getData }) => {
    const [showModal, setShowModal] = useState<Boolean> (false);
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState('');
    const [showDelete, setShowDelete] = useState(false);
    const [rowData, setRowData] = useState(undefined);
    const rData = useSelector((state: RootState) => state.wallet);
    const handleSearch = () => {
        if (search.length > 0) {
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
        }
    }
    const handlePerRowsChange = async (newPerPage, page) => {
        await getData(page, newPerPage, '');
    }
    const handlePageChange = (page) => {
        getData(page, rData.pageSize, '');
    };
    const handleRowDeleteClick = (row) => {
        setRowData(row);
        setShowDelete(true);
    }
    useEffect(() => {
        if (rData && rData.list && rData.list.length === 0) {
            dispatch(resetWalletToInit());
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
        }
    },[rData.list.length])
    const handleReset = () => {
        setSearch('');
        dispatch(resetWalletToInit());
        getData(Constant.defaultPageNumber, rData.pageSize, '');
    }
    const handleServerDelete = async () => {
        if (rowData) {
            const response = await deleteWallet(rowData.id);
            if (response) {
                dispatch(resetWalletToInit());
                dispatch(setWalletMessage("Deleted Successfully"));
                getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                setShowDelete(false);
            } else {
                dispatch(setWalletMessage("Some error occured!"));
            }
        }
    }

    const handleRowSelection = (row) => {
        console.log(row); // Row Selection Functionality can be written here
    }
    const handleAddButtonClick = () => {
        dispatch(setWalletMessage(''));
        hideShowForm('add');
    }
    const retirementButtonHandling = () => {
        setShowModal(true);
    }
  

    const closingModalRetirement = () => {
        setShowModal(false);
    }

    const columns = [
        { name: 'Saldo Actual', selector: row => row.ammount, sortable: true },
        { name: 'Activa Desde', selector: row => row.created_at, sortable: true },
        { name: 'última Actualización', selector: row => row.updated_at, sortable: true }
    ];
    return (
        <>
            {rData.list.map((r, i) => (
                <Card className="shadow mb-4">
                    <Card.Header className="py-3">
                        <h6 className="m-0 font-weight-bold text-primary">
                            <Button variant="light" className="btn-circle btn-sm ml-2" onClick={handleReset}><i className="fa fa-refresh"></i></Button>
                        </h6>
                    </Card.Header>
                    <Card.Body
                    style={{
                        display: 'grid',
                        placeItems: 'center',
                    }}
                    >
                        <h3 className='text-center'>Tu Balance Actual,</h3>
                        <h1 className='text-center'>{r.ammount.toLocaleString('es-MX')} USD</h1>

                        {/* <div className="table-responsive">
                            <DataTable
                                selectableRows={false}
                                onSelectedRowsChange={handleRowSelection}
                                paginationPerPage={Constant.defaultPageNumber}
                                paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
                                columns={columns} data={rData.list}
                                onChangeRowsPerPage={handlePerRowsChange}
                                paginationTotalRows={rData.totalCount}
                                className="table table-bordered"
                                pagination
                                paginationServer
                                onChangePage={handlePageChange}></DataTable>
                        </div> */}

                        <Button className="btn-rent-invert ml-2 float-right"
                            style={{
                                paddingLeft: '40px',
                                paddingRight: '40px',
                            }}
                            onClick={retirementButtonHandling}>
                            <span className="text">Detalles</span>
                        </Button>
                    </Card.Body>
                </Card>
            ))}
            {/* Este componente ya no realiza una transaccion solo muestra balance actual */}
            <ModalRetiramentWallet showModal={showModal} walletInfo={rData.list[0]} closeModal={closingModalRetirement}/>
        </>
    );
}

