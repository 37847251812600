import React,{Fragment,useState,useContext, useEffect} from 'react';
import {Link} from 'react-router-dom';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { Dropdown } from "react-bootstrap";
import ContactSlider from "../zenix/Home/ContactSlider";
import IndexTab from "../zenix/Home/IndexTab";
import Layout from "template";
import { MenuItems } from "template/MenuItems";
//Images
import moneda from  '../../assets/images/Moneda_Edited02_Png.png';


export const Aboutrent: React.FC = () => {

		  return (
			<Layout title="Conoce a Rent - Descripción del token"  icon="icon_DashboardColor_icon w-30" backlink={"#"} >
			<Fragment>
			<div className="container-fluid pt-body">
				<div className="row">
					<div className="col-xl-12 col-sm-12 m-t35">

						<div className="card card-coin">
							<div className="card-body text-center p-t-40 p-b-40">
								<div className="row">
									<div className="col-md-5">
										<img src={moneda} alt="Moneda Rent Representation" style={{width:'70%'}} />
									</div>
									<div className="col-md-7">
										<h1 className="text-left text-white m-b-40">Descripción de nuestro token Rent</h1>
										<h4 className="text-left m-b-10">
											<span className="personalizedicon"><div className="icon_RentLogoColor03_icon w-20"></div></span>
											¿Por qué Rent?
										</h4>
										<p className="text-left">
											El token permite invertir en todo tipo de bienes y servicios, a su vez, el contrato inteligente admite medir la participación de Rent, lo que actúa como un incentivo para mantener control y llevar medición exacta del ecosistema, haciéndolo sostenible en el tiempo.
										</p>
										<h4 className="text-left m-b-10 m-t-40">
											<span className="personalizedicon"><div className="icon_gobernanza w-20"></div></span>
											Gobernanza
										</h4>
										<p className="text-left">
											En nuestro marketplace los inversionistas a través de un smart contrat, podrán comprar sus tokens Rent, con los cuales tendrán la posibilidad de adquirir sus NFT’s respaldados con bienes y servicios, ofertados por las empresas del evento que cuentan con stands.
										</p>
										<h4 className="text-left m-b-10 m-t-40">
											<span className="personalizedicon"><div className="icon_security w-20"></div></span>
											Seguridad para todos
										</h4>
										<p className="text-left">
										Rent está desarrollado sobre la red ERC-20 de Ethereum (ETH), segunda criptomoneda en capitalización bursátil 150 BUSD “coinmarketcap.com”, lo que garantiza transparencia, seguridad y trazabilidad de todas las operaciones realizadas con nuestro token.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-xl-12 col-sm-12 m-t35">

						<div className="card card-coin">
							<div className="card-body text-center p-t-40 p-b-40">
								<h5 className="text-white text-left">TradingView</h5>
								<div dangerouslySetInnerHTML={{ __html: "<iframe src='http://hitracker.org/rent.html' style='height:50vh; width:100%; overflow-y:hidden' />"}} />
							</div>
						</div>
					</div>
				</div>
			</div>
			</Fragment>
      </Layout>
		)
	
}

// export default Dashboard;
