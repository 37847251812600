import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IWallet_Transactions {
id:number,
user_from:number,
user_to:number,
ammount:number,
transaction_status:string,
observation:string,
created_at:Date
}

interface IWallet_TransactionsData {
    list?: Array<IWallet_Transactions>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IWallet_TransactionsData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const wallet_transactionsSlice = createSlice({
    name: "wallet_transactions",
    initialState,
    reducers: {
        setWallet_TransactionsList: (state, _action: PayloadAction<IWallet_TransactionsData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetWallet_TransactionsToInit: (state) => {
            state = initialState;
        },
        setWallet_TransactionsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setWallet_TransactionsList, resetWallet_TransactionsToInit, setWallet_TransactionsMessage } = wallet_transactionsSlice.actions;

export default wallet_transactionsSlice.reducer;

