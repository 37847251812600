import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import { Button, Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { activateTopMenu, sidebarToggle } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import ehtIcon from  '../assets/images/rent/EtheColor_icon.svg';
import rentIcon from '../assets/images/rent/RentSimboloColor_icon.svg';
import btcIcon from  '../assets/images/rent/BitcoinColor_icon.svg';
import classNames from "classnames";
import { Link } from "react-router-dom";
import {WalletsModal} from '../components/modal/walletsModal';
import {TradingViewWidget} from '../components/tradingview/widget';

import {
  CartStateContext,
  CartDispatchContext,
  toggleCartPopup
} from "../contexts/cart";
import { CommonDispatchContext, setSearchKeyword } from "../contexts/common";
import CartPreview from "components/CartPreview";
import { ConstructorFragment } from "ethers/lib/utils";


const Topbar: React.FC<{title: string, icon: string, backlink: string}> = ({title, icon, backlink}) => {

    
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const handleActiveDropdown = (menuName: string) => {
        dispatch(activateTopMenu(menuName))
    }
    const isTopActiveMenu = useSelector((state: RootState) => state.template.topActiveMenu);
    const isSidebar = useSelector((state: RootState) => state.template.sidebar);
   
    let style = {
        headeryinit : {
            backgroundColor:"transparent",
            marginLeft: "0px",
            paddingLeft: "0px",
            paddingRight: "0px",
        },
        headeryscroll : {
            backgroundColor:"#DEEBEE",
            marginLeft: "-44px",
            paddingLeft: "44px",
            paddingRight: "44px",
        },
        topBarFull: {
            width: "calc(100% - 23rem)",
        },
        topBarToggled: {
            width: "calc(100% - 7.5rem)",
        },
        topBarFullScrolled: {
            width: "calc(100% - 17rem",
        },
        topBarToggledScrolled: {
            width: "calc(100% - 7.5rem)",
        },
        show:{
            display:'block',
            width: '100%',
            zIndex: '2',
            backgroundColor: '#DEEBEE',
            marginLeft: '-44px',
            marginTop: '239px',
            paddingLeft: '45px',
            paddingTop: '22px',
            paddingBottom: '12px',
        },
        hide:{
            display:'none',
        }
    };

    const [topBar, setTopBar]       = useState( style.topBarFull );
    const [topHeader, setTopHeader] = useState( style.headeryinit );
    const [subTitle, setSubTitle] = useState( style.hide );
    
    useEffect(() => {
        if (!isSidebar){
            setTopBar( style.topBarFull );
        }else{
            setTopBar( style.topBarToggled );
        }
    }, [isSidebar]);


    useEffect(() => {
        const handleScroll = event => {
        // console.log(window.scrollY);
        if(window.scrollY >= 35){
            if (!isSidebar){
                setTopBar(style.topBarFullScrolled);
            }else{
                setTopBar(style.topBarToggledScrolled);
            }
            setSubTitle( style.show );
            setTopHeader( style.headeryscroll );
            
        }else{
            if (!isSidebar){
                setTopBar( style.topBarFull );
                //setTopBar({ 'width': 'calc(100% - 425px)' });
            }else{
                setTopBar( style.topBarToggled );
                
            }
            setSubTitle( style.hide );
            setTopHeader( style.headeryinit );
        
        }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        console.log(topHeader);
    }, [topHeader]);


    const userProfile = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")): undefined;
    const handleLogout = () => {
        localStorage.clear();
        navigate('/', { replace: true });
    }
    

    // const avatar : string = ;

    const avatar = String(Object.values(userProfile)[12]);

    
    const styleIcon = {
        "width":"2.3rem",
        "height":"2.3rem"
      }

    const styletopbar = {
        'width':'100%',
    };
    

    const { items: cartItems, isCartOpen } = useContext(CartStateContext);
    const commonDispatch = useContext(CommonDispatchContext);
    const cartDispatch = useContext(CartDispatchContext);
    const cartQuantity = cartItems.length;
    const cartTotal = cartItems
      .map((item) => item.price * item.quantity)
      .reduce((prev, current) => prev + current, 0);
  
    const handleSearchInput = (event) => {
      return setSearchKeyword(commonDispatch, event.target.value);
    };
  
    const handleCartButton = (event) => {
      event.preventDefault();
      return toggleCartPopup(cartDispatch);
    };

    const handleBackLink = () => {
        navigate(backlink, { replace: true });
    }
    
    
    const [openModal, setopenModal] = useState(false);
    const handlerWallet = () => {
        const newpath = '/wallet';
        navigate(newpath, { replace: true });
    }

    const profile = (path) => {
        const newpath = '/profile';
        navigate(newpath, { replace: true });
    }
    // useEffect ( () => {
    //     setopenModal(false);
    // }, [openModal]) ;
    return (
        <>
        {openModal ? <WalletsModal letmodal={openModal}/>: null}
        <Nav className="navbar navbar-expand navbar-light topbar mb-4 static-top shadow_header" style={topBar}>
            <div className="bg-blue d-flex p-t-30 p-b-30" style={topHeader}>
                <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3" onClick={() => dispatch(sidebarToggle())}>
                    <i className="fa fa-bars"></i>
                </button>
                <div className="d-flex m-l-50 align-items-center fs-10 p-t-0" style={styletopbar}>
                    <div className="m-t-15" style= {{'width':'100%', 'maxHeight': '50px','overflow': 'hidden'}}>
                        <TradingViewWidget />
                        <Button onClick={handlerWallet} className="btn-rent-invert m-l-30 " title="Conectar Wallet" variant="primary">
                            Ir a mi Wallet
                        </Button>                        
                    </div>
                </div>

                <ul className="navbar-nav ml-auto" >
                    <li className={isTopActiveMenu === "Notification" ? "nav-item dropdown no-arrow mx-1 show" : "nav-item dropdown no-arrow mx-1"} >
                        {/* <div className="cart"> */}
                            <Nav.Link className="nav-link dropdown-toggle dn" data-toggle="dropdown" aria-haspopup="true" aria-expanded={isTopActiveMenu === "Notification" ? true : false} onClick={handleCartButton}>
                                <i className="fas fa-cart-shopping fa-fw text-white"></i>
                                {cartQuantity ? (
                                <span className="badge badge-danger badge-counter">{cartQuantity}</span>
                                ) : (
                                ""
                                )}
                            </Nav.Link>
                            
                            <CartPreview />
                        {/* </div> */}

                    </li>
                    {/* <li className={isTopActiveMenu === "Notification" ? "nav-item dropdown no-arrow mx-1 show" : "nav-item dropdown no-arrow mx-1"} >

                        <Nav.Link className="nav-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded={isTopActiveMenu === "Notification" ? true : false} onClick={() => handleActiveDropdown("Notification")}>
                            <i className="fas fa-bell fa-fw"></i>
                            <span className="badge badge-danger badge-counter">3+</span>
                        </Nav.Link>

                        <div className={isTopActiveMenu === "Notification" ? "dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in show" : "dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"}
                            aria-labelledby="alertsDropdown">
                            <h6 className="dropdown-header">
                                Alerts Center
                            </h6>
                            <a className="dropdown-item d-flex align-items-center" href="#">
                                <div className="mr-3">
                                    <div className="icon-circle bg-primary">
                                        <i className="fas fa-file-alt text-white"></i>
                                    </div>
                                </div>
                                <div>
                                    <div className="small text-gray-500">December 12, 2019</div>
                                    <span className="font-weight-bold">A new monthly report is ready to download!</span>
                                </div>
                            </a>
                            <a className="dropdown-item d-flex align-items-center" href="#">
                                <div className="mr-3">
                                    <div className="icon-circle bg-success">
                                        <i className="fas fa-donate text-white"></i>
                                    </div>
                                </div>
                                <div>
                                    <div className="small text-gray-500">December 7, 2019</div>
                                    $290.29 has been deposited into your account!
                                </div>
                            </a>
                            <a className="dropdown-item d-flex align-items-center">
                                <div className="mr-3">
                                    <div className="icon-circle bg-warning">
                                        <i className="fas fa-exclamation-triangle text-white"></i>
                                    </div>
                                </div>
                                <div>
                                    <div className="small text-gray-500">December 2, 2019</div>
                                    Spending Alert: We've noticed unusually high spending for your account.
                                </div>
                            </a>
                            <a className="dropdown-item text-center small text-gray-500"></a>
                        </div>
                    </li> */}


                    <div className="topbar-divider d-none d-sm-block"></div>
                    <li className={isTopActiveMenu === "Profile" ? "nav-item dropdown no-arrow show" : "nav-item dropdown no-arrow"} onClick={() => handleActiveDropdown("Profile")}>
                        <a className="nav-link dropdown-toggle" id="userDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="mr-2 d-none d-lg-inline">{userProfile? Object.values(userProfile)[1] : 'NA'}</span>
                            <img className="img-profile rounded-circle" src={avatar} />
                        </a>
                        <div className={isTopActiveMenu === "Profile" ? "dropdown-menu dropdown-menu-right shadow animated--grow-in show" : "dropdown-menu dropdown-menu-right shadow animated--grow-in"}
                            aria-labelledby="userDropdown">
                            <Button variant="link" className="dropdown-item" onClick={profile}>
                                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                {userProfile? Object.values(userProfile)[1]: 'NA'}
                            </Button>
                            {/* <Button variant="link" className="dropdown-item">
                                <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                                Settings
                            </Button>
                            <Button variant="link" className="dropdown-item">
                                <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                                Activity Log
                            </Button> */}
                            <div className="dropdown-divider"></div>
                            <Button variant="link" className="dropdown-item" onClick={handleLogout}>
                                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                Logout
                            </Button>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="dynamic_bar mb-sm-5 mb-3 align-items-center" style={ subTitle }>
                <h2 className="font-w600 text-white fs-20 mr-auto ">
                    <span className="mm-active">
                        <div className={ backlink != "#" ?"personalizedicon" : "dn"} onClick={handleBackLink} > <div className="pointer FlechaDespliegueLeftColor_icon w-20"></div> </div>
                        <div className="personalizedicon"> <div className={icon}></div> </div>
                    </span>
                    {title}
                </h2>
            </div>
        </Nav>
        </>
    );
};

export default Topbar;

