import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { setWalletList, setWalletMessage } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getWallet } from "services/walletService";
import Layout from "template";
import { Constant } from "template/Constant";
import { WalletForm } from "./form";
import { WalletTable } from "./table";
import { Wallet_Transactions } from "../wallet_transactions";

export const Wallet: React.FC = () => {
  const dispatch = useAppDispatch();
  const rData = useSelector((state: RootState) => state.wallet);
  const [row, setRow] = useState(undefined);
  const [action, setAction] = useState('');
  const getData = (page, pageSize, searchKey) => {
    getWallet(page, pageSize, searchKey).then((response) => {
      if (response && response.records) {
        dispatch(setWalletList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.totalCount, searchKey: searchKey }));
      } else {
		dispatch(setWalletMessage("No Record Found"));
      }
    })
  }

  const handleRowEdit = (rowData) => {
    setRow(rowData);
    dispatch(setWalletMessage(''));
    setAction('edit');
  }
  return (
    <>
    <Layout  title="Mi Wallet"  icon="icon_DashboardColor_icon w-30" backlink={"#"} >
      <div className="container-fluid pt-body">
        <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
            <h2 className="font-w600 mr-auto ">
                <span className="mm-active">
                  <div className="personalizedicon"> <div className="icon_DashboardColor_icon w-30"></div> </div> 
                </span> 
                Wallet
            </h2>
        </div>
        <div className="weather-btn ">
          {rData.message ?
            <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">{rData.message}
              <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => dispatch(setWalletMessage(''))}>
                <span aria-hidden="true">&times;</span>
              </Button>
            </Alert> : null}
        </div>
        <div className="d-flex flex-column min-vh-100">
          <div className='row'>
              <div className='col-md-4'>
                <WalletTable handleRowEdit={handleRowEdit} hideShowForm={setAction} getData={getData} />
              </div>
              <div className='col-md-8'>
                <Wallet_Transactions />
              </div>
          </div>
        </div>
      </div>
    </Layout >
    </>
  );
};

