import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {Chart, ArcElement,Tooltip, Legend} from 'chart.js'
Chart.register(ArcElement,Tooltip);


type Props = {
    sentOffers: number,
    receivedOffers: number,
    offersToShow: number,
    textTitle: string,
};

export const OffersDonuts: React.FC<Props> = ({ sentOffers,receivedOffers,textTitle,offersToShow}) => {

  // Preparar los datos para el gráfico
  const data = {
    labels: ["Recibidas","Realizadas"],
    datasets: [
      {
        label: '# Ofertas',
        data: [receivedOffers,sentOffers],
        backgroundColor: ['#101c2e','#62c5e3'],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    cutoutPercentage: 90,
    responsive: true,
    maintainAspectRatio: false,
    legend: {
                display: false,
                position: "bottom",
                align: "center",
    }

  };

  return (
    <div style={{ width: '160px', height: '160px' , display:'grid',placeItems:'center'}}>
      <Doughnut data={data} options={options}  />
      <h4 className='fs-18'>{textTitle}:{offersToShow}</h4>
    </div>
  );
};
