import React from "react";
import { Container, Navbar, Row } from "react-bootstrap";

const Footer: React.FC = () => {


    return null;
};

export default Footer;

