import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface INft_Favorites {
id:number,
ip:string,
user_id:string,
nft_id:string,
created_at:Date
}

interface INft_FavoritesData {
    list?: Array<INft_Favorites>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: INft_FavoritesData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const nft_favoritesSlice = createSlice({
    name: "nft_favorites",
    initialState,
    reducers: {
        setNft_FavoritesList: (state, _action: PayloadAction<INft_FavoritesData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetNft_FavoritesToInit: (state) => {
            state = initialState;
        },
        setNft_FavoritesMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setNft_FavoritesList, resetNft_FavoritesToInit, setNft_FavoritesMessage } = nft_favoritesSlice.actions;

export default nft_favoritesSlice.reducer;

