import React, { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

type distributionsSpvSocietyData = {
    distributionsSpvSociety: any,
    defaultFranchiseId: number,
    settingDefaultFranchiseID: (idFranchise: number) => void;
    defaultFranchise: any;
}



export const DistributionsSpvSociety: React.FC<distributionsSpvSocietyData> = ({ distributionsSpvSociety, defaultFranchiseId, settingDefaultFranchiseID, defaultFranchise }) => {
console.log(defaultFranchise);


    return (<div className="col-xl-12 col-xxl-8" >
        <div className="card">
            <div className="card-header border-0 flex-wrap  select-btn">
                <div className="mb-3">
                    <h4 className="fs-18 ">Distribución de participación SPV donde soy socio</h4>
                </div>
            </div>
            <div className="card-body" style={{ display: 'flex' }}>
                <div className="col-xl-7">
                    <Tabs

                        activeKey={defaultFranchiseId}
                        id="fill-tab-example"
                        className="mb-3"
                        fill
                        onSelect={(k) => settingDefaultFranchiseID(parseInt(k))}
                        style={
                            {
                                backgroundColor: '#a6b8be',
                                padding: '10px',
                                borderRadius: '10px',
                            }
                        }
                    >
                        {
                            distributionsSpvSociety?.map((item: any) => (
                                <Tab eventKey={item.franchise_id} title={item.description?.substring(1,item.description.length-3)} key={item.franchise_id}>

                                    <div className="grid-container-three-columns">
                                        <div className="grid-item">
                                            <span className='grid-title d-block'>Mi inversion</span>
                                            <span className='grid-content fs-18'>USD {item.total_price?.toLocaleString()}</span>

                                        </div>

                                        <div className="grid-item">
                                            <span className='grid-title d-block'>No. de Tickets</span>
                                            <span className='grid-content fs-18'>{item.total_participations}</span>
                                        </div>

                                        <div className="grid-item">
                                            <span className='grid-title d-block'>Participacion por Ticket</span>
                                            <span className='grid-content fs-18'>{item.fd_tk_participacion_ticket}</span>
                                        </div>
                                        <div className="grid-item">
                                            <span className='grid-title d-block'>Valor de Tickets</span>
                                            <span className='grid-content fs-18'>{item.fd_tk_valor_ticket}</span>
                                        </div>
                                        <div className="grid-item">
                                            <span className='grid-title d-block'>Total Tickets SPV</span>
                                            <span className='grid-content fs-18'>{item.fd_tk_total_participaciones}</span>
                                        </div>
                                        <div className="grid-item">
                                            <span className='grid-title d-block'>Costo Total SPV</span>
                                            <span className='grid-content fs-18'>USD {((item.total_price / item.total_participations) * parseInt(item.fd_tk_total_participaciones))?.toLocaleString()}</span>
                                        </div>
                                    </div>

                                </Tab>
                            ))
                        }
                    </Tabs>
                </div>

                <div className="col-xl-5" style={{
                    width: '35%',
                    borderLeft: '1px solid #a6b8be',
                    height: '300px',
                    boxSizing: 'border-box',
                    marginLeft: '45px',
                    paddingLeft: '45px',
                    display: 'flex',
                }}> 

                    <img src={defaultFranchise?.franchiseImage} alt={defaultFranchise?.description} style={{
                        height: '295px',
                        width: '210px',
                        borderRadius: '10px',
                        boxShadow:' 0px 10px 4px rgba(0, 0, 0, 0.1)'
                    }}/>
                    <div style={{paddingLeft:'20px',paddingRight:'20px'}}>
                        <span className='grid-title d-block' style={{color:'#62C5E3'}}>Rentabilidad Estimada:</span>
                        {defaultFranchise?.fd_tk_revenue_esperado} <br />
                        <span className='grid-title d-block' style={{color:'#62C5E3'}}>Revalorizacion Estimada:</span>
                        {defaultFranchise?.fd_llc_revalorizacion_estimada} <br />

                    </div>
                </div>

            </div>
        </div>
    </div>
    )
}
