import { APIService } from "services";


export const getWallet_Financials = async () => {
    let res;
    res = await wallet_financials_details();
    if(res && res.data){
        return res.data;
    }else{
        return { records:[], totalCount:0 }

    }
    
}


export const wallet_financials_details = () => {
return APIService.api().get(`/wallet/info/details`)
}

