import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface INfts {
path(path: any): void;
id:number,
file:string,
contact:string,
description:string,
author:string,
owner:number,
status:string,
views?:number,
favorites?:number,
price:number,
sale_date:Date,
contact_address?:string,
token_id?:string,
token_standard?:string,
blockchain?:string,
metadata:string,
franchise_id:number,
created_at:Date,
updated_at?:Date,
deleted_at?:Date,
totalParticipaciones:number
}

interface INftsData {
    list?: Array<INfts>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: INftsData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const nftsSlice = createSlice({
    name: "nfts",
    initialState,
    reducers: {
        setNftsList: (state, _action: PayloadAction<INftsData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetNftsToInit: (state) => {
            state = initialState;
        },
        setNftsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setNftsList, resetNftsToInit, setNftsMessage } = nftsSlice.actions;

export default nftsSlice.reducer;

