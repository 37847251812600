import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface commissionDetails {
    total_comissions: number,
    total_referrals_transactions: number
}

export interface IWallet_Financials_Details {
    participationsNumber: number,
    availableAmount: number,
    comissions: commissionDetails,
    investments: number,
    total_nfts_details: any, // Deberías evitar usar 'any' si es posible
}

interface IWallet_Financials_DetailsData {
    financialDetails: IWallet_Financials_Details | null;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IWallet_Financials_DetailsData = {
    searchKey: '',
    financialDetails: null, // Mejor usar 'null' que una cadena vacía
    message: '',
};

const wallet_financials_details = createSlice({
    name: "wallet_financials_details",
    initialState,
    reducers: {
        setWallet_Financials_Details: (state, _action: PayloadAction<IWallet_Financials_DetailsData|null>) => {
            state.financialDetails = _action.payload.financialDetails;
        },
        resetWallet_Financials: (state) => {
            state.searchKey = initialState.searchKey;
            state.financialDetails = initialState.financialDetails;
            state.message = initialState.message;
        },
        setWallet_FinancialsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setWallet_Financials_Details, resetWallet_Financials, setWallet_FinancialsMessage } = wallet_financials_details.actions;

export default wallet_financials_details.reducer;
