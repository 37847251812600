import React, { Fragment, useState, useEffect } from 'react';
import Layout from "template";
import { wallet_financials_details } from '../../services/wallet_financials_details';
import { useDispatch, useSelector } from 'react-redux';
import { setWallet_Financials_Details, setWallet_FinancialsMessage } from "redux/actions";
import { RootState } from "redux/reducers";

import { MyWalletDashboard } from './MyWalletDashboard';
import { ActiveParticipations } from './ActiveParticipations';
import { ConsolidatedInfoOffers } from './ConsolidatedInfoOffers';
import { IncomeAndExpenses } from './incomeAndExpenses'; 
import {ConsolidatedOGraphCont} from './ConsolidatedOGraphCont';
import { DistributionsSpvSociety } from './DistributionsSpvSociety';
import { ConsolidatedFranchiseRental } from './ConsolidatedFranchiseRental';
import { Loader } from 'components/loader';


export const Dashboard: React.FC = () => {

	const dispatch = useDispatch();
	const statefinancialDetails = useSelector((state: RootState) => state.wallet_financials_details);
	const { financialDetails } = statefinancialDetails;   // Redux State

	const [defaultFranchise, setDefaultFranshise] = useState(null); // Local State
    
	const settingDefaultFranchise = (franchise_id: number) => {
		const newDefaultFranchise = financialDetails.total_nfts_details.find((item: any) => item.franchise_id === franchise_id);
		setDefaultFranshise(newDefaultFranchise);

	}

	useEffect(() => {
		let isMounted = true; 
	  
		const getWalletFinancials = async () => {
		  try {
			const res = await wallet_financials_details();
			if (isMounted) { 
			  if (res && res.data) {
				dispatch(setWallet_Financials_Details({ financialDetails: res.data }));
				dispatch(setWallet_FinancialsMessage("ok"));
				setDefaultFranshise(res.data.total_nfts_details[0]);
			  } else {
				dispatch(setWallet_FinancialsMessage("Hubo un error al obtener los detalles financieros"));
			  }
			}
		  } catch (error) {
			console.error("Error fetching wallet financials:", error);
			if (isMounted) { 
			  dispatch(setWallet_FinancialsMessage("Hubo un error al obtener los detalles financieros"));
			}
		  }
		};
	  
		getWalletFinancials();
	  
		return () => {
		  isMounted = false;
		};
	  }, [dispatch]);
	  


	const participationsDonutInfo = financialDetails?.total_nfts_details.map((item) => ({
		franchise_id: item.franchise_id,
		description: item.description,
		total_participations: item.total_participations,
	}));

	const consolidatedInfoOffersData = financialDetails?.total_nfts_details.map((item) => ({
		franchise_id: item.franchise_id,
		description: item.description,
		total_participations: item.total_participations,
		ofertas_recibidas: item.ofertas_recibidas,
		ofertas_realizadas: item.ofertas_realizadas,
	}));

	const distributionsSpvSocietyData = financialDetails?.total_nfts_details.map((item) => ({
		franchise_id: item.franchise_id,
		description: item.description,
		total_participations: item.total_participations,
		total_price:item.total_price,
		fd_tk_valor_ticket:item.fd_tk_valor_ticket,
		fd_tk_total_participaciones:item.fd_tk_total_participaciones,
		fd_tk_participacion_ticket:item.fd_tk_participacion_ticket
	}));



	

	return (
		<Layout title="Dashboard" icon="icon_DashboardColor_icon w-30" backlink={"#"} >
			<Fragment>
				<div className="container-fluid pt-body">
					<div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
						<h2 className="font-w600 mr-auto ">
							<span className="mm-active">
								<div className="personalizedicon"> <div className="icon_DashboardColor_icon w-30"></div> </div>
							</span>
							Dashboard
						</h2>
						<div className="weather-btn "></div>
					</div>
                    {financialDetails != null && financialDetails?
					
					<>
					<div className="row">
						<ActiveParticipations participationsDonutInfo={participationsDonutInfo} />  
						
						<MyWalletDashboard
							participationsNumber={participationsDonutInfo.reduce((acc, item) => acc + item.total_participations, 0)}
							availableAmount={financialDetails?.availableAmount}
							comissions={financialDetails?.comissions} />

					</div>
					<div className="row">
						{ financialDetails != null && consolidatedInfoOffersData != null &&
						     <>
						     <ConsolidatedOGraphCont 
							 sentOffers={defaultFranchise?.ofertas_realizadas} 
							 receivedOffers={defaultFranchise?.ofertas_recibidas}/>
						     
							 <ConsolidatedInfoOffers 
							 consolidatedInfoOffersData ={consolidatedInfoOffersData}
							 defaultFranchiseId={defaultFranchise?.franchise_id}
							 settingDefaultFranchiseID={settingDefaultFranchise}/>
							 </>
						}
						
					</div>

					<div className="row">
						<DistributionsSpvSociety 
						 distributionsSpvSociety={distributionsSpvSocietyData} 
						 defaultFranchiseId={defaultFranchise?.franchise_id}
						 defaultFranchise={defaultFranchise}
						 settingDefaultFranchiseID={settingDefaultFranchise}
						 />
					</div>
					<div className="row">

						{financialDetails != null && <IncomeAndExpenses data={defaultFranchise?.monthly_financials} />	}
						
					</div>

					<div className="row">

						<ConsolidatedFranchiseRental defaultFranchise={defaultFranchise}/>	
						
					</div>
					</>
					:
					<Loader />
					}
					
				</div>
			</Fragment>
		</Layout>
	)

}

// export default Dashboard;