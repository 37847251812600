import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IFranchises {
id:number,
name:string,
country:string,
city:string,
description:string,
rents:string,
pricing_list:string,
discounts:string,
min_invest:number,
max_invest:number,
expexted_profit:string,
franchise_type:string,
created_at:Date,
avatar:string,
qualification:number,
status: number,
rents_disponibles: number,
}

interface IFranchisesData {
    list?: Array<IFranchises>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IFranchisesData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const franchisesSlice = createSlice({
    name: "franchises",
    initialState,
    reducers: {
        setFranchisesList: (state, _action: PayloadAction<IFranchisesData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetFranchisesToInit: (state) => {
            state = initialState;
        },
        setFranchisesMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setFranchisesList, resetFranchisesToInit, setFranchisesMessage } = franchisesSlice.actions;

export default franchisesSlice.reducer;

