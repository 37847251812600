import { set } from "lodash";
import { useState } from "react";
import Modal from "react-modal";

type WalletInfo = {
    ammount: number,
    created_at: Date,
    id: number,
    current_in_hold: number,
    updated_at: Date,
    user_id: number,
  };

type Props = {
    showModal: Boolean;
    walletInfo: WalletInfo;
    closeModal: () => void;
};


export const ModalRetiramentWallet: React.FC<Props> = ({ showModal,walletInfo,closeModal}) => {
    
        
    const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
        },
        iconForm: {
          marginRight: "10px",
        },
        formRegister: {
          width: "85%",
        },
        colorSVG: {
          color: "#FFFFFF",
        },
      };
      let ammountOfferSent = (walletInfo?.current_in_hold / 10) * 100;
        return (
            <>
            <Modal
                isOpen={showModal}
                
                style={customStyles}
                contentLabel="Example Modal">
                <div className="modal_Box">
                    <div className="row modal_header m-l-5 m-r-5">
                        <div className="col-sm-11 p-l-0">
                            <h2>Tu Wallet</h2>
                        </div>
                        <div className="col-sm-1 p-r-0">
                            <div className="pull-right p-5px default-select bg-white b-none">
                                <div className="personalizedicon mr-0 pt-0" >
                                    <div className="icon_Close_icon w-15" onClick={closeModal}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row modal_body">
                        <div className="col-md-12">
                            <p>Saldo Actual: {walletInfo?.ammount?.toLocaleString('es-MX')} USD</p>
                            <p>Actualmente en Hold: {walletInfo?.current_in_hold?.toLocaleString('es-MX')} USD</p>
                            <p>Saldo para ofertas realizadas: {ammountOfferSent?.toLocaleString('es-MX')} USD</p>
                            <p>Saldo disponible: {(walletInfo?.ammount-ammountOfferSent)?.toLocaleString('es-MX')} USD</p>
                        </div>
                    </div>
                </div>
            </Modal>
            </>
        )
}