import React,{Fragment} from 'react';
import { useLocation } from 'react-router-dom'
import Layout from "template";
import bg from  '../../assets/images/fondocomingsoon.png';
import q1 from  '../../assets/images/ComingSoonBlanco_Icon.png';

function getTexto(query){
	switch(query){
        case "mercados":
          return "Pronto podrás no solo generar rentabilidad a través de tus participaciones (inversiones) sino también de la venta de estas; y/o comprar la de otros usuarios."
		case "ofertas":
			return "Esta función te permitirá interactuar comercialmente con otros inversionistas de la plataforma y obtener mayor rentabilidad producto de la venta de tus participaciones en franquicias inmobiliaris (NFT’s). Tus ofertas serán visualizadas por la comunidad en ‘Mercados secundarios’."
		case "staking":
			return "Estamos trabajando para ti. próximamente estaremos activiando esta función"
		case "littio":
			return "Estamos trabajando para ti. próximamente estaremos activiando esta función"
		case "cuentas":
			return "Estamos trabajando para ti. próximamente estaremos activiando esta función"
		case "familia":
			return "Estamos trabajando para ti. próximamente estaremos activiando esta función"
        default:
          return "Próximamente en Rent"
      }
}

export const ComingSoon: React.FC = () => {
	
	const location = useLocation();
	let uri = location.pathname;
    let parts = uri.split("/");
    let query = parts[2];

	const content = getTexto(query);
	const style = {
		background: {
			backgroundImage: "url("+bg+")",
			backgroundSize:"100% 100%",
			height:"80vh",
			backgroundRepeat:"no-repeat"
		},
		image: {
			width: "30%",

		},
		p : {
			paddingLeft:'30%',
			paddingRight:'30%',
		}
	}
	return (
		<Layout title="Próximamente"  icon="icon_DashboardColor_icon w-30" backlink={"#"} >
			<Fragment>
			<div className="container-fluid pt-body " style={style.background}>
				<div className="row">
					<div className="col-xl-12 col-sm-12 m-t35 text-center p-100">
						<img src={q1} alt="q1" style={style.image} />
						<h5 className="text-justify p-t-40" style={style.p} >{content}</h5>
					</div>
				</div>
			</div>
			</Fragment>
	</Layout>
	)
}