import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface INft_Owners {
id:number,
nft_id:string,
user_id:string,
status:number,
buy_price:string,
sell_price?:string,
created_at:Date
}

interface INft_OwnersData {
    list?: Array<INft_Owners>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: INft_OwnersData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const nft_ownersSlice = createSlice({
    name: "nft_owners",
    initialState,
    reducers: {
        setNft_OwnersList: (state, _action: PayloadAction<INft_OwnersData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetNft_OwnersToInit: (state) => {
            state = initialState;
        },
        setNft_OwnersMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setNft_OwnersList, resetNft_OwnersToInit, setNft_OwnersMessage } = nft_ownersSlice.actions;

export default nft_ownersSlice.reducer;

