import React, { useState } from 'react'

type props = {
    defaultFranchise: any;
}


export const ConsolidatedFranchiseRental: React.FC<props>  = ({ defaultFranchise}) => {
    

    return (
        <div className="col-xl-12 col-xxl-8">
            <div className="card" style={{
                paddingBottom: "2%",
            }}>
                <div className="card-header border-0 flex-wrap  select-btn">
                    <div>
                        <h4 className="fs-18 ">Consolidado franquicia seleccionada <br /> ingresos y rentabilidad</h4>
                    </div>
                </div>
                <div className="card-body">
                    <div className="grid-container-three-columns">
                        <div className="grid-item">
                            <span className='grid-title d-block'>Mi inversión SPV</span>
                            <span className='grid-content fs-18'>USD {defaultFranchise?.total_price?.toLocaleString()}</span>

                        </div>
                        <div className="grid-item">
                            <span className='grid-title d-block'>Valorizacion promedio</span>
                            <span className='grid-content fs-18'>USD {(defaultFranchise?.total_price * 12 /100).toLocaleString()}</span>

                        </div>
                        <div className="grid-item">
                            <span className='grid-title d-block'>Rentabilidad rentas</span>
                            <span className='grid-content fs-18'>USD </span>

                        </div>
                        <div className="grid-item">
                            <span className='grid-title d-block'>Mi utilidad</span>
                            <span className='grid-content fs-18'>USD </span>

                        </div>
                        <div className="grid-item">
                            <span className='grid-title d-block'>Total estimada</span>
                            <span className='grid-content fs-18'>USD </span>

                        </div>
                        <div className="grid-item">
                            <span className='grid-title d-block'>Rentabilidad rentas</span>
                            <span className='grid-content fs-18'>USD </span>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
