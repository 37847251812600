import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppDispatch } from "redux/store";
import { tokenAPICALL } from "services/authService";
import { setJWTToken } from "redux/actions";
import { setError } from "redux/slices/auth";
import { Alert, Button, Form, InputGroup, FormControl } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { Constant } from "template/Constant";
import logo from '../assets/images/rent/RentLogoBlack_icon.svg';
import {WindowModal} from '../components/users/modal';
import {ForgotPassword} from '../components/users/forgotPasswordmodal';
import {DataTreatmentModal} from '../components/modal/dataTreatmentModal';

const Login: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const rData = useSelector((state: RootState) => state.authToken);
    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        onSubmit: async (values) => {
            const response = await tokenAPICALL(values.username, values.password);
            if (response) {
                dispatch(setJWTToken(response));
                setopenModal(true)
                // navigate('/franchises', { replace: true });
            } else {
                dispatch(setError("Invalid Credentials"))
            }
        },
        validationSchema: yup.object({
            username: yup.string().trim().required('Username is required'),
            password: yup.string().trim().required('Password is required'),
        }),
    });

    const handleRegister = () => {
        navigate('/register', { replace: true });
    }

    const [passwordType, setPasswordType] = useState("password");
    const [passwordInput, setPasswordInput] = useState("");
    const handlePasswordChange =(evnt)=>{
        setPasswordInput(evnt.target.value);
    }
    const togglePassword =()=>{
      if(passwordType==="password")
      {
       setPasswordType("text")
       return;
      }
      setPasswordType("password")
    }
    const handleForgot = () => {
        setforgotModal(true);
    }
    // useEffect(() => {
    //     document.body.classList.toggle('LoginBp', true);
    // }, [])

    const [openModal, setopenModal] = useState(false);
    const [openModalDataTreatment, setopenModalDataTreatment] = useState(false);
    const [forgotModal, setforgotModal] = useState(false);

    return (
        <div className="container-fluid LoginBp">
            <div className="container">
                <div className="row d-flex justify-content-center align-items-center vh-100">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="card o-hidden border-0 bg-white shadow-lg">
                            <div className="card-body p-0 login-card">
                                <div className="row">
                                    <div className="col-lg-12 col-md-offset-12">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <img src={logo} alt="logo" className="logo" />
                                            </div>
                                            <div className="text-center">
                                                <h1 className="h4 text-gray-900 mb-4">Inicio de sesión</h1>
                                            </div>
                                            <Form className="user" onSubmit={formik.handleSubmit}>
                                                <Form.Group>
                                                    <div className="personalizedicon hidden-xs">
                                                        <div className="icon_NombreBlack_icon w-30"></div>
                                                    </div>
                                                    <Form.Control type="text" name="username" className="form-control-user" value={formik.values.username}
                                                        id="username"
                                                        placeholder="Username"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        isInvalid={!!formik.touched.username && !!formik.errors.username}
                                                        isValid={!!formik.touched.username && !formik.errors.username}
                                                    ></Form.Control>
                                                    {formik.errors.username && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {formik.errors.username}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>
                                                <Form.Group>
                                                    <div className="personalizedicon hidden-xs">
                                                        <div className="icon_KeyBlack_icon w-30"></div>
                                                    </div>
                                                
                                                    <Form.Control type={passwordType} name="password" placeholder="Password" className="form-control-user typepassword" value={formik.values.password}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        isInvalid={!!formik.touched.password && !!formik.errors.password}
                                                        isValid={!!formik.touched.password && !formik.errors.password}
                                                    ></Form.Control>

                                                    <div className="input-group-btn">
                                                        <a className="" onClick={togglePassword}>
                                                            <div className="personalizedicon">
                                                                { passwordType==="password"? <div className="icon_MostrarKeyBlack_icon w-20"></div> :<div className="icon_OcultarKeyBlack_icon w-20"></div> }
                                                            </div>
                                                        </a>
                                                    </div>
                                                    {formik.errors.password && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {formik.errors.password}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>

                                                <Button type="submit" className="btn-user btn-block btn-rent-white mt-50" variant="primary">Iniciar Sesión</Button>
                                            </Form>
                                            {rData.errorMessage ?
                                                <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">{rData.errorMessage}
                                                    <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => dispatch(setError(''))}>
                                                        <span aria-hidden="true">&times;</span>
                                                    </Button>
                                                </Alert> : null}
                                            <div className="text-center text-black footerLogin">
                                                <p>
                                                    <small>
                                                        Revisa nuestra
                                                        <a href="" onClick={(event) => {event.preventDefault(); setopenModalDataTreatment(true)}}> politica de tratamiento de datos</a>
                                                    </small>
                                                </p>
                                                <a href="#"  onClick={handleForgot}>Recupera tu Contraseña</a>
                                                <br />
                                                ¿No tienes Cuenta? <a href="#" onClick={handleRegister}>Creala Aquí!</a>
                                            </div>
                                        </div>
                                        {openModal ? <WindowModal email={formik.values.username} letmodal={true} />: null}
                                        {forgotModal ? <ForgotPassword letmodal={true} />: null}
                                        {openModalDataTreatment ? <DataTreatmentModal letmodal={true} /> : null}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    );
};

export default Login;


