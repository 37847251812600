import React from 'react'
import { useNavigate } from "react-router";

type comissionsDetails = {
  total_comissions: number,
  total_referrals_transactions: number
};

type Props = {
  participationsNumber: number;
  availableAmount: number;
  comissions: comissionsDetails;
};

export const MyWalletDashboard: React.FC<Props> = ({ participationsNumber, availableAmount, comissions }) => {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/wallet');
  };
  return (
    <div className="col-xl-8 col-sm-6 m-t35">
      <div className="card" style={{display:'grid',placeItems:'center'}}>
        <div className="card-header border-0" style={{ alignSelf: 'flex-start', justifySelf: 'start' , marginLeft:'5%'}} >

          <h4 className="fs-18 ">Mi WALLET</h4>

        </div>
        <div className="card-body border-0" >

          <div className="grid-container-two-columns">
            <div className="grid-item">
              <span className='grid-title d-block'>Disponible para Retirar</span>
              <span className='grid-content fs-18'><i className=" m-t-5 m-r-5 fas fa-warehouse"></i>USD {availableAmount?.toLocaleString()}</span>
            </div>
            <div className="grid-item">
              <span className='grid-title d-block'>Disponible Rent</span>
              <span className='grid-content fs-18'><i className=" m-t-5 m-r-5 fas fa-warehouse"></i>{participationsNumber}</span>
            </div>
          </div>

          <h4 className="fs-18 ">Consolidado comisiones finales</h4>

          <div className="grid-container-three-columns">
            <div className="grid-item">
              <span className='grid-title d-block'>Comisiones Referidos</span>
              <span className='grid-content fs-18'><i className=" m-t-5 m-r-5 fas fa-warehouse"></i>USD {comissions?.total_comissions?.toLocaleString()}</span>
            </div>
            <div className="grid-item">
              <span className='grid-title d-block'>Transacciones Referidos</span>
              <span className='grid-content fs-18'><i className=" m-t-5 m-r-5 fas fa-warehouse"></i>{comissions.total_referrals_transactions}</span>
            </div>
            <div className="grid-item">
              <span className='grid-title d-block'>Transacciones Referidos</span>
              <span className='grid-content fs-18'><i className=" m-t-5 m-r-5 fas fa-warehouse"></i>{comissions.total_referrals_transactions}</span>
            </div>
            <div className="grid-item">
              <span className='grid-title d-block'>Transacciones Referidos</span>
              <span className='grid-content fs-18'><i className=" m-t-5 m-r-5 fas fa-warehouse"></i>{comissions.total_referrals_transactions}</span>
            </div>
            <div className="grid-item">
              <span className='grid-title d-block'>Transacciones Referidos</span>
              <span className='grid-content fs-18'><i className=" m-t-5 m-r-5 fas fa-warehouse"></i>{comissions.total_referrals_transactions}</span>
            </div>
            <div className="grid-item">
              <span className='grid-title d-block'>Transacciones Referidos</span>
              <span className='grid-content fs-18'><i className=" m-t-5 m-r-5 fas fa-warehouse"></i>{comissions.total_referrals_transactions}</span>
            </div>
          </div>
        </div>

        <div className="card-footer col-xl-8  flex grid-container-two-columns">
          <button className='btn-rent-invert' onClick={handleClick}>Solicitar Retiro</button>
          <button className='btn-rent-invert-second' onClick={handleClick}>Recarga</button>
        </div>

      </div>
    </div>
  )
}
