import React, { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import {Chart, ArcElement,Tooltip, Legend} from 'chart.js'
Chart.register(ArcElement,Tooltip);

interface DonutInfoFranchiseObjectWithColor extends donutInfoFranchiseObject {
  color: string;
}

type donutInfoFranchiseObject = {
  franchise_id: number;
  description: string;
  total_participations: number;
};

type Props = {
  participationsDonutInfo: DonutInfoFranchiseObjectWithColor[];
};

export const ParticipationsDonut: React.FC<Props> = ({ participationsDonutInfo }) => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const palette = ['#101c2e', '#36A2EB', '#4BC0C0', '#62c5e3'];

  // Crear una copia del objeto "participationsDonutInfo" con los cambios necesarios
  const updatedDonutInfo = participationsDonutInfo?.map((item) => ({
    ...item,
    color: palette[Math.floor(Math.random() * palette.length)],
  }));

  // Evento onClick del gráfico
  const handleClick = (event: any) => {
    if (event.length > 0) {
      const clickedElementIndex = event[0]._index;
      const selectedCategory = updatedDonutInfo[clickedElementIndex].description;
      setSelectedCategory(selectedCategory);
    } else {
      setSelectedCategory(null);
    }
  };

  // Preparar los datos para el gráfico
  const data = {
    labels: updatedDonutInfo?.map((item) => item.description),
    datasets: [
      {
        label: '# Participaciones',
        data: updatedDonutInfo?.map((item) => item.total_participations),
        backgroundColor: updatedDonutInfo?.map((item) => item.color),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    cutoutPercentage: 90,
    responsive: true,
    maintainAspectRatio: false,
    onClick: handleClick, // Asignar el evento onClick al gráfico
    legend: {
                display: false,
                position: "bottom",
                align: "center",
    }

  };

  return (
    <div style={{ width: '280px', height: '320px' }}>
      <Doughnut data={data} options={options}  />
      {selectedCategory && <p>Has seleccionado: {selectedCategory}</p>}
    </div>
  );
};
