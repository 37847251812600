import { APIService } from "services";

export const getWallet_Transactions = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllWallet_Transactions(pageNo,pageSize);
    }
    else{
        try {
            res = await searchWallet_Transactions(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data){
    return res.data;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addWallet_Transactions = (data) => {
return APIService.api().post(`/wallet_transactions`,data)
}
export const updateWallet_Transactions = (id,data) => {
return APIService.api().patch(`/wallet_transactions/${id}`,data)
}
export const getAllWallet_Transactions = (pageNo,pageSize) => {
return APIService.api().get(`/wallet_transactions/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const getOneWallet_Transactions = (id) => {
return APIService.api().get(`/wallet_transactions/${id}`)
}
export const searchWallet_Transactions = (searchKey,pageNo,pageSize) => {
return APIService.api().get(`/wallet_transactions/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const deleteWallet_Transactions = (id) => {
return APIService.api().delete(`/wallet_transactions/${id}`)
}
