import React,{Fragment,useState,useContext, useEffect} from 'react';
import {Link} from 'react-router-dom';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { Dropdown } from "react-bootstrap";
import ContactSlider from "../zenix/Home/ContactSlider";
import IndexTab from "../zenix/Home/IndexTab";
import Layout from "template";
import { MenuItems } from "template/MenuItems";
//Images
import bg from  '../../assets/images/MontanaRoadmap.png';
import q1 from  '../../assets/images/01.png';
import q2 from  '../../assets/images/02.png';
import q3 from  '../../assets/images/03.png';
import q4 from  '../../assets/images/04.png';
import q5 from  '../../assets/images/05.png';
import q6 from  '../../assets/images/06.png';
import q7 from  '../../assets/images/07.png';


export const Roadmap: React.FC = () => {
	
	const style = {
		background: {
			backgroundImage: "url("+bg+")",
			backgroundSize:"100% 100%",
			height:"80vh",
			backgroundRepeat:"no-repeat"
		},
		image: {
			width: "30%",
		},
		column7 : {
			width: "14.2%",
			paddingLeft:"10px",
			paddingRight:"10px",
		},
		positionq1:{
			marginTop:"319px",

		},
		positionq2:{
			marginTop:"228px",

		},
		positionq3:{
			marginTop:"177px",

		},
		positionq4:{
			marginTop:"78px",

		},
		positionq5:{
			marginTop:"80px",

		},
		positionq6:{
			marginTop:"-14px",

		},
		positionq7:{
			marginTop:"-162px",

		},
	}
	return (
		<Layout title="Titulo"  icon="icon_DashboardColor_icon w-30" backlink={"#"} >
			<Fragment>
			<div className="container-fluid pt-body">
				<div className="row">
					<div className="col-xl-12 col-sm-12 m-t35">

						<div className="card card-coin" style={style.background}>
							<div className="card-body text-center p-t-40 p-b-40">
								<div>
									<h1 className="text-left text-white mt-50">Roadmap</h1>
									<h5 className="text-left text-white">Ruta de planificación de desarrollo de Rent</h5>
									<p className="text-left text-white">Infografía roadmap Rent</p>
								</div>
								<div className="row">
									<div style={style.column7}>
										<div style={style.positionq1}>
											<h4 className="text-left">2022</h4>
											<p className="text-left fs-13">Selección de proyectos inmobiliarios con potencial de alta valorización.</p>
											<img src={q1} alt="q1" style={style.image} />
										</div>
									</div>
									<div style={style.column7}>
										<div style={style.positionq2}>
											<h4 className="text-left">2022</h4>
											<p className="text-left fs-13">Creación de token Rent. Despliegue de DAO SMART CONTRAT.</p>
											<img src={q2} alt="q2" style={style.image} />
										</div>
									</div>
									<div style={style.column7}>
										<div style={style.positionq3}>
											<h4 className="text-left">2022</h4>
											<p className="text-left fs-13">Creación primera colección de 300 NFT’s</p>
											<img src={q3} alt="q3" style={style.image} />
										</div>
									</div>
									<div style={style.column7}>
										<div style={style.positionq4}>
											<h4 className="text-left">2022</h4>
											<p className="text-left fs-13">Launch Rents y primer marketplace de NFT’s con respaldo inmoliriario</p>
											<img src={q4} alt="q4" style={style.image} />
										</div>
									</div>
									<div style={style.column7}>
										<div style={style.positionq5}>
											<h4 className="text-left">2022</h4>
											<p className="text-left fs-13">Legalización de contratos de inversión en Real Estate</p>
											<img src={q5} alt="q5" style={style.image} />
										</div>
									</div>
									<div style={style.column7}>
										<div style={style.positionq6}>
											<h4 className="text-left">2022</h4>
											<p className="text-left fs-13">Segunda emisión de Rents hasta por 25M</p>
											<img src={q6} alt="q6" style={style.image} />
										</div>
									</div>
									<div style={style.column7}>
										<div style={style.positionq7}>
											<h4 className="text-left">2022</h4>
											<p className="text-left fs-13">Tercera y cuarta emisión de Rents por 75M</p>
											<img src={q7} alt="q7" style={style.image} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			</Fragment>
	</Layout>
	)
	
}

// export default Dashboard;